import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const user = getToken()

const initialState = {
  user: user ? user : null,
  loading: false,
  success: false,
  error: null,
  message: ''
}

export const login = createAsyncThunk('login', async (user, ThunkAPI) => {
  try {
    const response = await client.post('/login', user)

    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  } catch (error) {
    return ThunkAPI.rejectWithValue(error.response.data)
  }
})

export const logout = createAsyncThunk(
  'auth/logout',
  async (user, ThunkAPI) => {
    localStorage.removeItem('user')
    return null
  }
)
export const confirmPassword = createAsyncThunk(
  'confirmPassword',
  async (password, { rejectWithValue }) => {
    try {
      const response = await client.post('/confirmPassword', password, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const changePassword = createAsyncThunk(
  'changePassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await client.put('/change-password', data, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false
      state.success = false
      state.error = null
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.user = action.payload
        state.message = 'User logged in successfully'
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
        state.success = false
        state.message = 'User login failed'
      })
      .addCase(logout.pending, (state) => {
        state.loading = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.user = null
        state.message = 'User logged out successfully'
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
        state.success = false
        state.message = 'User logout failed'
      })
      .addCase(confirmPassword.pending, (state) => {
        state.loading = true
      })
      .addCase(confirmPassword.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.message = 'Password confirmed successfully'
      })
      .addCase(confirmPassword.rejected, (state, action) => {
        state.error = action.payload.message
        state.loading = false
        state.success = false
        state.message = 'Password confirmation failed'
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.user = null
        state.message = 'Password changed successfully'
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
        state.success = false
        state.message = 'Password confirmation failed'
      })
  }
})

export const { reset } = authSlice.actions
export default authSlice.reducer
