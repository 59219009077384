import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPassword } from '../../../services/Redux/slices/authSlice';


const PasswordModal = ({ isOpen, onClose, onConfirm, toDeleteEvent = false }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(confirmPassword({ password }))
      .then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          onConfirm();
        } else {
          setError('Incorrect password');
        }
      })
      .finally(() => {
        setPassword('');
      });
  };

  if (!isOpen) {
    return null;
  }
  const handleClose = () => {
    setError('');
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg">

        {
          toDeleteEvent &&
          <div class="flex items-center p-4 mb-4 text-sm w-96 text-red-800 border border-red-500 rounded-lg bg-red-50" role="alert">
            <div>
              <span class="font-medium"> Warning:</span> This action is irreversible. Deleting this event will permanently remove all associated tickets and links. You can download the ticket reports for this event before proceeding.
            </div>
          </div>
        }
        <h2 className="text-xl font-medium mb-4">Enter Password to Confirm Deletion</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="p-2 border rounded w-full mb-4"
          placeholder="Password"
        />
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-gray-200 rounded"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
