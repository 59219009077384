import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTicketById, getTickets } from '../services/Redux/slices/tickets'
import EditTicketModal from '../components/Core/CRUD/Edit/EditTicket'
import { format, parseISO } from 'date-fns'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import Pagination from '../components/Core/Pagination/Pagination'
import { getEvents } from '../services/Redux/slices/EventsSlice'
import CountUp from "react-countup";
import PDFGenerator from '../components/Core/PDF/PDFGenerator'

function Tickets() {
  const { tickets, loading, currentPage, totalPages, totalTickets ,ticketsWithoutPagination } = useSelector((state) => state.tickets)
  const { events } = useSelector((state) => state.events) || []
  const dispatch = useDispatch()


  const [actualPage, setActualPage] = useState(1)
  const [buyerName, setBuyerName] = useState();
  const [participant, setParticipant] = useState();
  const [participantPhone, setParticipantPhone] = useState();
  const [eventId, setEventId] = useState();

  useEffect(() => {
    dispatch(getEvents())
  }, [dispatch])



  useEffect(() => {
    dispatch(getTickets({ page: actualPage, limit: 10, buyerName, participant, participantPhone, eventId }))
  }, [dispatch, actualPage, buyerName, participant, participantPhone, eventId])

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setActualPage(page);
    }
  };

  const columns = [
    { label: 'buyer', key: 'buyer' },
    { label: 'participant', key: 'participant' },
    { label: 'participant Phone', key: 'participantPhone' },
    {
      label: 'status',
      key: 'status',
      render: (row) => {
        const statusColor = row.status === 'valid' ? 'green' : row.status === 'entred' ? 'red' : 'black';
        const formattedStatus = row.status === 'valid' ? 'Valid' : row.status === 'entred' ? 'Entred' : row.status;
        return (
          <span style={{ color: statusColor }} className="py-2 font-bold">
            {formattedStatus}
          </span>
        );
      }
    },
    { label: 'event', key: 'event.name' },
    {
      label: 'event date',
      key: 'event.dateStart',
      render: (row) => {
        if (!row.event?.dateStart) {
          return 'No start date'
        }
        const date = parseISO(row?.event?.dateStart)
        return isNaN(date) ? 'Invalid date' : format(date, 'dd/MM/yyyy HH:mm')
      }
    },
    {
      label: 'Scanned By',
      key: 'scannedBy',
      render: (row) => {
        return (
          <div className='py-1'>
            {row.scannedBy
              ? `${row.scannedBy.profile?.firstName || ''} ${row.scannedBy.profile?.lastName || ''}`
              : "-"}
          </div>
        );
      }
    },
    { label: 'ticket Type', key: 'type.name' },
    { label: 'ticket pdf', key: 'pdf' }
  ]

  const toEditColumns = [
    { label: 'Status', key: 'status', type: 'text', required: true }
  ]

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null)

  const handleEdit = (updated) => {
    console.log('Updated :', updated)
    setIsEditModalOpen(false) // Close the edit modal after editing
  }

  const handleEditClick = (ticket) => {
    setSelectedTicket(ticket)
    console.log(selectedTicket)
    setIsEditModalOpen(true)
  }


  // if (loading) {
  //   return <LoadingSpinner></LoadingSpinner>
  // }
  return (
    <div className='container mx-auto'>
      <div className=' flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium '>Tickets</p>
        <div>
          <PDFGenerator tickets={ticketsWithoutPagination} />
        </div>
      </div>

      <div className='flex flex-col md:flex-row items-start md:items-center md:justify-between border-b md:border-primaryColorLight md:pb-1 md:mb-4'>
        <div className='flex flex-col lg:flex-row mb-4 md:mb-0'>

          <div className='flex flex-col'>
            <label className='text-gray-500 text-sm'> Filter By <span className='text-primaryColorLight'> Buyer </span>  </label>
            <input
              type='text'
              placeholder='Buyer Name' // Input field for buyer name filter
              className='p-2 border rounded mb-1 me-1 w-52 h-8'
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>

          <div className='flex flex-col'>
            <label className='text-gray-500 text-sm'> Filter By <span className='text-primaryColorLight'> Participant </span>  </label>
            <input
              type='text'
              placeholder='Participant Name' // Input field for buyer name filter
              className='p-2 border rounded mb-1 me-1 w-52 h-8'
              onChange={(e) => setParticipant(e.target.value)}
            />
          </div>

          <div className='flex flex-col'>
            <label className='text-gray-500 text-sm'> Filter By <span className='text-primaryColorLight'> Participant Phone </span>  </label>
            <input
              type='text'
              placeholder='Participant Phone' // Input field for buyer name filter
              className='p-2 border rounded mb-1 me-1 w-52 h-8'
              onChange={(e) => setParticipantPhone(e.target.value)}
            />
          </div>


          <div className='flex flex-col'>
            <label className='text-gray-500 text-sm'> Filter by <span className='text-primaryColorLight'> Event </span> </label>
            <select
              className='p-1 border rounded me-1 mb-1 w-52 h-8'
              onChange={(e) => setEventId(e.target.value)}
            >
              <option value={undefined}>
                All
              </option>
              {events?.map((option) => (
                <option key={option._id} value={option?._id}>
                  {option?.name}
                </option>
              ))}
            </select>
          </div>



        </div>

        <p className='text-m text-gray-500 mt-2 md:mt-0'>
          Total tickets :
          <span className='text-lg font-medium text-primaryColor'>
            <CountUp end={totalTickets} />
          </span>{' '}
        </p>
      </div>

      <div className='my-5'>
        {
          loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <DataTable
                columns={columns}
                data={tickets}
                onEditClick={handleEditClick}
                onDeleteClick={(ticket) => dispatch(deleteTicketById(ticket))}
              />
              {isEditModalOpen && (
                <EditTicketModal
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  onEdit={handleEdit}
                  ticket={selectedTicket}
                  columns={toEditColumns}
                />
              )}
            </>
          )
        }
      </div>

      <div className='flex justify-end'>
        {totalPages > 0 &&
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />}
      </div>
    </div>
  )
}

export default Tickets
