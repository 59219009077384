import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import 'tailwindcss/tailwind.css'
import getCroppedImg from './getCroppedFunc'

function Crop({ image , onCroppedImage , format}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [isPreview, setIsPreview] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const showCroppedImage = useCallback(async () => {
    try {
      if (croppedAreaPixels) {
        // Calculate the size of the square crop area
        const smallestSide = Math.min(croppedAreaPixels.width, croppedAreaPixels.height);
        
        // Adjust the crop area to make it square
        const squareCrop = {
          x: croppedAreaPixels.x + (croppedAreaPixels.width - smallestSide) / 2,
          y: croppedAreaPixels.y + (croppedAreaPixels.height - smallestSide) / 2,
          width: smallestSide,
          height: smallestSide,
        };
        
        // Crop the image using the square crop area
        const croppedImage = await getCroppedImg(image, squareCrop, format);
        
        // Update state
        setCroppedImage(croppedImage);
        setIsPreview(true);
        if (onCroppedImage) {
          onCroppedImage(croppedImage);
        }
      }
    } catch (error) {
      console.error('Error cropping image:', error);
      setCroppedImage(null);
    }
  }, [croppedAreaPixels, image, format, onCroppedImage]);
  

  const resetCrop = () => {
    setIsPreview(false)
    setCroppedImage(null)
  }

  return (
    <div className="flex flex-col items-start justify-start">
      {!isPreview ? (
        <>
          <div className=" crop-container  mt-4 relative w-full h-60 bg-gray-200">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="controls mt-4 w-80">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value)
              }}
              className="zoom-range w-full"
            />
          </div>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            onClick={showCroppedImage}
          >
            crop
          </button>
        </>
      ) : (
        <>
          <div className="preview-container relative bg-gray-200 mt-4">
            <img src={croppedImage} alt="Cropped" className=" object-cover "  />
          </div>
          <button
            className="mt-4 bg-gray-500 text-white py-2 px-4 rounded"
            onClick={resetCrop}
          >
            Crop Again
          </button>
        </>
      )}
    </div>
  )
}

export default Crop
