import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../services/Redux/slices/authSlice';
import { resetEvent } from '../services/Redux/slices/EventsSlice';
import { resetLink } from '../services/Redux/slices/LinksSlice';
import { resetTeam } from '../services/Redux/slices/TeamSlice';
import { resetTickets } from '../services/Redux/slices/tickets';
import { resetTicketType } from '../services/Redux/slices/TicketTypes';


const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    dispatch(resetTeam())
    dispatch(resetTicketType())
    dispatch(resetTickets())
    dispatch(resetLink())
    dispatch(resetEvent())
  }, [dispatch]);

  return (
    <div>
    </div>
  );
};

export default Logout;
