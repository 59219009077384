import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer'
import { PDFDownloadLink } from '@react-pdf/renderer'
import logo from './MCTpdf.png'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 20
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  logo: {
    width: 100,
    height: 'auto'
  },
  date: {
    fontSize: 12,
    textAlign: 'right'
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 20,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol0: {
    width: '17%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCol1: {
    width: '17%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCol2: {
    width: '11%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCol3: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCol4: {
    width: '17%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCol5: {
    width: '15%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCol6: {
    width: '13%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1
  },
  tableCell: { margin: "auto" , marginTop:5 ,padding:5, fontSize: 10 },
  statusValid: { color: 'green' },
  statusEntered: { color: 'red' },
  pageNumber: { textAlign: 'center', marginTop: 10, fontSize: 12 },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    padding: 10,
  },
})

const formatDate = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}

// Create Document Component
const MyDocument = ({ title, tickets }) => (
  <Document>
    <Page size='A4' style={styles.page} wrap>
      <View style={styles.header}>
        <Image src={logo} style={styles.logo} />
        <Text style={styles.date}>{formatDate(new Date())}</Text>
      </View>

      <Text style={styles.title}>{title}</Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol0}>
            <Text style={styles.tableCell}>Buyer</Text>
          </View>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCell}>Participant</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCell}>P.Phone</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCell}>Status</Text>
          </View>
          <View style={styles.tableCol4}>
            <Text style={styles.tableCell}>Event</Text>
          </View>
          <View style={styles.tableCol5}>
            <Text style={styles.tableCell}>Event date</Text>
          </View>
          <View style={styles.tableCol6}>
            <Text style={styles.tableCell}>Ticket Type</Text>
          </View>
        </View>
        {tickets.map((ticket, index) => (
          <View style={styles.tableRow} key={index} wrap={false}>
            <View style={styles.tableCol0}>
              <Text style={styles.tableCell}>{ticket.buyer}</Text>
            </View>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>{ticket.participant}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>{ticket.participantPhone}</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text
                style={[
                  styles.tableCell,
                  ticket.status === 'valid'
                    ? styles.statusValid
                    : styles.statusEntered
                ]}
              >
                {ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}
              </Text>
            </View>
            <View style={styles.tableCol4}>
              <Text style={styles.tableCell}>{ticket.event.name}</Text>
            </View>
            <View style={styles.tableCol5}>
              <Text style={styles.tableCell}>
                {new Date(ticket.event.dateStart).toISOString().slice(0, 10)}
              </Text>
            </View>
            <View style={styles.tableCol6}>
              <Text style={styles.tableCell}>{ticket.type.name}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={styles.footer}>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
      </View>
    </Page>
  </Document>
)

// PDF Generator Component
const PDFGenerator = ({ tickets }) => {
  return (
    <button className='bg-[#D73556] p-2 rounded-sm text-white px-3 font-medium'>
      <PDFDownloadLink
        document={<MyDocument title='Tickets Report' tickets={tickets} />}
        fileName={`Tickets_report_${formatDate(new Date())}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Generating PDF...' : 'Download PDF Report'
        }
      </PDFDownloadLink>
    </button>
  )
}

export default PDFGenerator
