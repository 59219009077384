import React from 'react'
import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import logo from '../../assets/images/logo.svg'

function MobileHeader(props) {
  const { onToggle } = props

  return (
    <header className='flex h-11 items-center justify-between bg-mainDark md:hidden bg-primaryColor'>
      <div role='banner' className='flex items-center gap-2 pl-4'>
        <div className='relative h-10 w-12'>
          <img src={logo} alt='logo' />
        </div>
      </div>
      <button onClick={onToggle} className='h-full bg-hoverLink px-4'>
        <HamburgerMenuIcon className='text-white' />
      </button>
    </header>
  )
}

export default MobileHeader
