import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteLink, getLinks } from '../services/Redux/slices/LinksSlice'
import AddLink from '../components/Core/CRUD/Add/AddLink'
import {
  FaDollarSign,
  FaClipboard,
  FaClipboardList,
  FaCheck
} from 'react-icons/fa'
import EditLinkModal from '../components/Core/CRUD/Edit/EditLink'
import { getEvents } from '../services/Redux/slices/EventsSlice'
import { getTicketTypes } from '../services/Redux/slices/TicketTypes'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import Pagination from '../components/Core/Pagination/Pagination'
import { Roles } from '../enum'
import { getMyTeamMembers } from '../services/Redux/slices/TeamSlice'
import CountUp from 'react-countup'

function Links() {
  const { links, currentPage, totalPages, loading, totalLinks } = useSelector(
    (state) => state.links
  )
  const dispatch = useDispatch()
  const { events } = useSelector((state) => state.events) || []
  const { types } = useSelector((state) => state.types) || []
  const { team } = useSelector((state) => state.teams) || []
  const { user } = useSelector((state) => state.auth)

  const [copiedLink, setCopiedLink] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedLink, setSelectedLink] = useState(null)
  const [actualPage, setActualPage] = useState(1)
  const [filterCreatedBy, setFilterCreatedBy] = useState()
  const [buyerName, setBuyerName] = useState()
  const [eventId, setEventId] = useState()

  useEffect(() => {
    dispatch(getEvents())
    dispatch(getTicketTypes())
    dispatch(getMyTeamMembers())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getLinks({
        page: actualPage,
        limit: 10,
        filterCreatedBy,
        buyerName,
        eventId
      })
    )
  }, [dispatch, actualPage, filterCreatedBy, buyerName, eventId])

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setActualPage(page)
    }
  }

  const handleCopyLink = (link) => {
    setCopiedLink(link)
    setTimeout(() => {
      setCopiedLink(null)
    }, 5000) // to hide the confirmation icon after 5 seconds
  }

  const toAddColumns = [
    { label: 'Buyer Name', key: 'BuyerName', type: 'text', required: true },
    { label: 'Buyer Email', key: 'BuyerEmail', type: 'text', required: false },
    { label: 'Buyer phone', key: 'numTel', type: 'text', required: false },
    { label: 'Expiry Date', key: 'ExpiryDate', type: 'datetime-local' },
    {
      label: 'Max Tickets Number',
      key: 'nbTicketMax',
      type: 'number',
      required: true
    },
    { label: 'Event', key: 'event', type: 'text', required: true },
    { label: 'Ticket Type', key: 'ticketType', required: true }
  ]

  const columns = [
    { label: 'Buyer Name', key: 'BuyerName' },
    {
      label: 'Buyer Email',
      key: 'BuyerEmail',
      render: (row) => (row.BuyerEmail ? row.BuyerEmail : '-')
    },
    {
      label: 'Buyer Phone',
      key: 'numTel',
      render: (row) => (row.numTel ? row.numTel : '-')
    },
    {
      label: 'Status',
      key: 'status',
      render: (row) => {
        let statusStyle = ''
        switch (row.status) {
          case 'Paid':
            statusStyle = 'text-green-500 font-bold'
            break
          case 'Unpaid':
            statusStyle = 'text-orange-500 font-bold' // Bold and orange for unpaid
            break
          case 'Expire':
            statusStyle = 'text-red-500 font-bold' // Bold and red for expired
            break
          default:
            statusStyle = 'font-bold' // Default bold for other statuses
        }
        return <span className={statusStyle}>{row.status}</span>
      }
    },
    { label: 'Max Tickets Number', key: 'nbTicketMax', type: 'number' },
    { label: 'Event', key: 'event.name' },
    {
      label: 'Created By',
      key: 'createdBy',
      render: (row) => {
        return (
          <span>
            {row.createdBy?.profile?.firstName}{' '}
            {row.createdBy?.profile?.lastName}
          </span>
        )
      }
    },
    { label: 'Ticket Type', key: 'ticketType.name' },
    {
      label: 'Payment Url',
      key: 'linkUrl',
      render: (row) => (
        <div className='flex items-center space-x-2'>
          {row.ticketType?.price === 0 ? (
            <span className='text-green-500 font-semibold'>Free</span>
          ) : (
            <>
              <button
                onClick={() => window.open(row.linkUrl, '_blank')}
                className='bg-blue-500 text-white p-2 rounded hover:bg-blue-700'
                title='Pay Now'
              >
                <FaDollarSign />
              </button>
              <CopyToClipboard
                text={row.linkUrl}
                onCopy={() => handleCopyLink(row.linkUrl)}
              >
                <button
                  className='text-gray-700 p-2 rounded hover:bg-gray-400'
                  title='Copy Payment Link'
                >
                  {copiedLink === row.linkUrl ? (
                    <FaCheck className='text-green-500' />
                  ) : (
                    <FaClipboard />
                  )}
                </button>
              </CopyToClipboard>
            </>
          )}
        </div>
      )
    },
    {
      label: 'Form Url',
      key: '_id',
      render: (row) => (
        <div className='flex items-center space-x-2'>
          <button
            onClick={() => window.open(`/ticket/${row._id}`, '_blank')}
            className='bg-blue-500 text-white p-2 rounded hover:bg-blue-700'
            title='Complete Form'
          >
            <FaClipboardList />
          </button>
          <CopyToClipboard
            // text={`https://tickets.mineclap.app/ticket/${row._id}`}
            // onCopy={() =>
            //   handleCopyLink(`https://tickets.mineclap.app/ticket/${row._id}`)
            // }
            text={`${
              process.env.NODE_ENV === 'production'
                ? `https://tickets.mineclap.app/ticket/${row._id}`
                : `${window.location.origin}/ticket/${row._id}`
            }`}
            onCopy={() =>
              handleCopyLink(
                `${
                  process.env.NODE_ENV === 'production'
                    ? `https://tickets.mineclap.app/ticket/${row._id}`
                    : `${window.location.origin}/ticket/${row._id}`
                }`
              )
            }
          >
            <button
              className='text-gray-700 p-2 rounded hover:bg-gray-400'
              title='Copy Form Link'
            >
              {copiedLink === `${window.location.origin}/ticket/${row._id}` ? (
                <FaCheck className='text-green-500' />
              ) : (
                <FaClipboard />
              )}
            </button>
          </CopyToClipboard>
        </div>
      )
    }
  ]

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleEdit = (updated) => {
    console.log('Updated :', updated)
    setIsEditModalOpen(false) // Close the edit modal after editing
  }

  const handleEditClick = (link) => {
    setSelectedLink(link)
    console.log(selectedLink)
    setIsEditModalOpen(false)
  }

  const toEditColumns = [
    { label: 'Status', key: 'status', type: 'text', required: true }
  ]

  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium'>Links</p>
        <button
          onClick={openModal}
          className='bg-[#D73556] p-2 rounded-sm text-white px-3 font-medium'
        >
          Add Link
        </button>
        <AddLink
          events={events}
          types={types}
          columns={toAddColumns}
          isOpen={isModalOpen}
          onClose={closeModal}
        ></AddLink>
      </div>

      <div className='flex flex-col md:flex-row items-start md:items-center md:justify-between border-b md:border-primaryColorLight md:pb-1 md:mb-4'>
        <div className='flex flex-col lg:flex-row mb-4 md:mb-0'>
          <div className='flex flex-col'>
            <label className='text-gray-500 text-sm'>
              {' '}
              Filter By <span className='text-primaryColorLight'>
                {' '}
                Buyer{' '}
              </span>{' '}
            </label>
            <input
              type='text'
              placeholder='Buyer Name' // Input field for buyer name filter
              className='p-2 border rounded mb-1 me-1 w-52 h-8'
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>

          <div className='flex flex-col'>
            <label className='text-gray-500 text-sm'>
              {' '}
              Filter by <span className='text-primaryColorLight'>
                {' '}
                Event{' '}
              </span>{' '}
            </label>
            <select
              className='p-1 border rounded me-1 mb-1 w-52 h-8'
              onChange={(e) => setEventId(e.target.value)}
            >
              <option value={undefined}>All</option>
              {events.map((option) => (
                <option key={option.value} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          {user.role === Roles.USER && (
            <div className='flex flex-col'>
              <label className='text-gray-500 text-sm'>
                {' '}
                Filter by{' '}
                <span className='text-primaryColorLight'> Seller </span>{' '}
              </label>
              <select
                className='p-1 border rounded mb-1 me-1 w-52 h-8'
                onChange={(e) => setFilterCreatedBy(e.target.value)}
              >
                <option value={undefined}>All</option>
                {team.map((option) => (
                  <option key={option.value} value={option._id}>
                    {option.profile.firstName} {option.profile.lastName}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <p className='text-m text-gray-500 mt-2 md:mt-0'>
          Total links :
          <span className='text-lg font-medium text-primaryColor'>
            <CountUp end={totalLinks} />
          </span>
        </p>
      </div>

      <div className='my-5'>
        {loading && !isModalOpen ? (
          <LoadingSpinner />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={links}
              onEditClick={handleEditClick}
              onDeleteClick={(link) => dispatch(deleteLink(link))}
              disabled={true}
            />
            {isEditModalOpen && (
              <EditLinkModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onEdit={handleEdit}
                link={selectedLink}
                columns={[]}
              />
            )}
          </>
        )}
      </div>

      <div className='flex justify-end'>
        {totalPages > 0 && (
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  )
}

export default Links
