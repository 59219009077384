import React from 'react'

import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'

const SidebarLink = ({ icon, label, href, isActive, setActivePage ,open, onToggleMobileSideBar}) => {
  const IconComponent = icon
  const location = useLocation()
  isActive = location.pathname === href
  return (
    <div onClick={() => {
      setActivePage(href)
      open && onToggleMobileSideBar()
    }
    }>
      <Link to={href}>
        <a
          className={clsx(
            'group flex items-center gap-[20px] border-l-4 py-3.5 pl-7',
            isActive
              ? 'border-selectedItem bg-pink-700 w-full'
              : 'border-transparent hover:bg-pink-700'
          )}
        >
          <div className='group-hover:text-white'>
            <IconComponent width={'20'} height={'20'} fill={'#FFFFFF'} />
          </div>

          <span
            className={clsx(
              'label whitespace-nowrap text-sm',
              isActive
                ? 'font-medium text-white'
                : 'font-normal text-white group-hover:text-white'
            )}
          >
            {label}
          </span>
        </a>
      </Link>
    </div>
  )
}

export default SidebarLink
