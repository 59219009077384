import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, addMonths, subMonths } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const EventCalendar = ({ events }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [calendarDays, setCalendarDays] = useState([]);

    useEffect(() => {
        const start = startOfMonth(currentDate);
        const end = endOfMonth(currentDate);
        setCalendarDays(eachDayOfInterval({ start, end }));
    }, [currentDate]);

    const nextMonth = () => setCurrentDate(addMonths(currentDate, 1));
    const prevMonth = () => setCurrentDate(subMonths(currentDate, 1));

    const isToday = (day) => isSameDay(new Date(), day);

    const isEventDay = (day) =>
        events?.some(event => isSameDay(new Date(event.dateStart), day));

    const getEventForDay = (day) => {
        const event = events?.find(event => isSameDay(new Date(event.dateStart), day));
        return event ? event.name : null;
    };

    return (
        <div className="block rounded-xl border p-4 h-[378px] shadow-md hover:shadow-xl  transition hover:border-pink-500/10 hover:shadow-pink-500/10">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-bold text-dark">Event Calendar</h2>
                <div className="flex space-x-2">
                    <button onClick={prevMonth} className="p-1 rounded-full hover:bg-gray-200">
                        <ChevronLeft size={24} />
                    </button>
                    <button onClick={nextMonth} className="p-1 rounded-full hover:bg-gray-200">
                        <ChevronRight size={24} />
                    </button>
                </div>
            </div>
            <div className="text-center mb-4">
                <h3 className="text-lg font-semibold">{format(currentDate, 'MMMM yyyy')}</h3>
            </div>
            <div className="grid grid-cols-7 gap-2">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                    <div key={day} className="text-center font-medium text-gray-500">
                        {day}
                    </div>
                ))}
                {calendarDays.map((day, index) => {
                    const eventName = getEventForDay(day);

                    return (
                        <div key={index} className="relative group flex items-center justify-center">
                            <div
                                className={`
                  w-8 h-8 flex items-center justify-center rounded-full relative cursor-pointer group
                  ${!isSameMonth(day, currentDate) ? 'text-gray-400' : 'text-gray-800'}
                  ${isEventDay(day) ? 'bg-blue-100' : ''}
                  ${isToday(day) ? 'bg-green-200 border border-green-500' : ''}
                `}
                            >
                                {format(day, 'd')}
                                {isEventDay(day) && (
                                    <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-red-500 rounded-full"></div>
                                )}
                            </div>
                            {/* Tooltip */}
                            {eventName && (
                                <div className="absolute left-1/2 w-36 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block text-center bg-gray-800 text-white text-xs px-2 py-1 rounded z-10">
                                    {eventName}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EventCalendar;