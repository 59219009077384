import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTicketTypeById, getTicketTypes } from '../services/Redux/slices/TicketTypes'
import AddTicketType from '../components/Core/CRUD/Add/AddTicketType'
import EditTypeModal from '../components/Core/CRUD/Edit/EditTicketTypeModal'

function TicketTypes() {
const { types } = useSelector((state) => state.types)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTicketTypes())
  }, [dispatch])

  const columns = [
    { label: 'Ticket Type Name', key: 'name' },
    { label: 'Price', key: 'price' },
  ]
  const toAddColumns = [
    { label: 'Ticket Type Name', key: 'name', type: 'text', required: true },
    { label: 'Price', key: 'price', type: 'number', required: true },
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedType, setSelectedType] = useState(null)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  
  const handleEdit = (updated) => {
    console.log('Updated :', updated)
    setIsEditModalOpen(false) // Close the edit modal after editing
  }

  const handleEditClick = (type) => {
    setSelectedType(type)
    console.log(selectedType)
    setIsEditModalOpen(true) }
  return (
    <div className='container mx-auto'>
     <div className='flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium'>Tickets Type</p>
        <button
          onClick={openModal}
          className='bg-[#D73556] p-2 rounded-sm text-white px-3 font-medium'
        >
          Add TicketType
        </button>
        <AddTicketType
          columns={toAddColumns}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </div>
      <DataTable columns={columns} data={types}    
      onEditClick={handleEditClick}
      onDeleteClick={(type) => dispatch(deleteTicketTypeById(type))} ></DataTable>
       {isEditModalOpen && (
        <EditTypeModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEdit}
          type={selectedType}
          columns={toAddColumns}
        />
      )}
    </div>
  )
}


export default TicketTypes