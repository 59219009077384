import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { getEvents } from '../../../services/Redux/slices/EventsSlice'
import 'react-calendar/dist/Calendar.css';
import EventCalendar from '../../Layout/Calender/EventCalender';
import { Roles, RolesUI } from '../../../enum';
import CountUp from "react-countup";
import { getTickets } from '../../../services/Redux/slices/tickets';
import { getLinks } from '../../../services/Redux/slices/LinksSlice';
import { getMyTeamMembers } from '../../../services/Redux/slices/TeamSlice';
import { Link } from 'react-router-dom'
import {
    BiLinkExternal
} from 'react-icons/bi'
import EditPassword from '../CRUD/Edit/EditPassword';
import LoadingSpinner from '../../Layout/LoadingSpinner';
import clsx from 'clsx';

const Home = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getEvents())
        dispatch(getMyTeamMembers())
        dispatch(getTickets({ page: undefined, limit: undefined, buyerName: undefined, participant: undefined, participantPhone: undefined, eventId: undefined }))
        dispatch(getLinks({ page: undefined, limit: undefined, filterCreatedBy: undefined, buyerName: undefined, eventId: undefined }))
    }, [dispatch])

    const { user, error } = useSelector((state) => state.auth)
    const { events, loading } = useSelector((state) => state.events) || []
    const { totalLinks, links, totalLinksBySeller } = useSelector((state) => state.links)
    const { totalTickets } = useSelector((state) => state.tickets)
    const { team } = useSelector((state) => state.teams)


    const [activeIndex, setActiveIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)

    useEffect(() => {
        if (events.length > 0) {
            const interval = setInterval(() => {
                setActiveIndex((current) => (current + 1) % events.length);
            }, 10000); // Change slide every 10 seconds

            return () => clearInterval(interval);
        }
    }, [events.length]);

    const prevSlide = () => {
        setActiveIndex((current) => (current - 1 + events.length) % events.length);
    };

    const nextSlide = () => {
        setActiveIndex((current) => (current + 1) % events.length);
    };

    const getCurrentDate = () => new Date().toISOString().split('T')[0];

    const getBadgeInfo = (eventDate) => {
        const currentDate = getCurrentDate();
        if (eventDate >= currentDate) {
            return { color: 'bg-pink-500', text: 'Coming Soon' };
        } else {
            return { color: 'bg-green-500', text: 'Completed' };
        }
    };

    const formatName = (name) => {
        return name.split(' ').map(part =>
            part.split('').map(char =>
                /[a-zA-Z]/.test(char) ? char.toUpperCase() : char
            ).join('')
        ).join(' ');
    };

    const displayEvents = events.length > 0 ? events : [{
        name: 'No Events Available',
        image: 'https://imgs.search.brave.com/RpScMLne2tKfAHpm6VbyD6qIcHmUIqgXuc-aMm_vpfg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA3LzY2Lzc1Lzcy/LzM2MF9GXzc2Njc1/NzIwOV9qRFU2VnZz/VXQ2b0I5V1U0bWZP/bzFualFkQVdlMG5y/MS5qcGc',
        dateStart: new Date()
    }];

    if (loading) {
        return <LoadingSpinner />
    }

    return (

        <div className="container mx-auto text-dark">
            <div>
                <p className='text-3xl sm:text-5xl font-semibold'>
                    Hello,
                    <span className='text-3xl sm:text-5xl font-semibold text-primaryColorLight ms-1'>
                        {/* {user?.name.split(' ').map(part => part[0].toUpperCase() + part.slice(1)).join(' ')} */}
                        {user?.name}
                    </span> !
                </p>
                <p className='text-xl sm:text-3xl font-normal text-gray-500 mt-2'> Your ticket dashboard at a glance... </p>
            </div>

            <div className="mx-auto  py-8  sm:py-12">
                {
                    user?.role === Roles.USER ?
                        <dl className="mt-6 grid grid-cols-1 gap-4 sm:mt-8 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="relative flex flex-col rounded-lg border border-gray-300 px-4 py-8 text-center shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10">
                                <Link to="/dashboard/events" className="absolute top-2 right-2 text-xl text-gray-500">
                                    <BiLinkExternal />
                                </Link>
                                <dt className="order-last text-lg font-medium text-gray-500">Events Created</dt>
                                <dd className="text-4xl font-extrabold text-primaryColorLight md:text-5xl">
                                    <CountUp end={events?.length || 0} />
                                </dd>
                            </div>
                            <div className="relative flex flex-col rounded-lg border border-gray-300 px-4 py-8 text-center shadow-md hover:shadow-xl  transition hover:border-pink-500/10 hover:shadow-pink-500/10">
                                <Link to="/dashboard/links" className="absolute top-2 right-2 text-xl text-gray-500">
                                    <BiLinkExternal />
                                </Link>
                                <dt className="order-last text-lg font-medium text-gray-500 ">Links Generated</dt>
                                <dd className="text-4xl font-extrabold text-primaryColorLight md:text-5xl">
                                    <CountUp end={totalLinks || 0} />
                                </dd>
                            </div>
                            <div className="relative flex flex-col rounded-lg border border-gray-300 px-4 py-8 text-center shadow-md hover:shadow-xl  transition hover:border-pink-500/10 hover:shadow-pink-500/10">
                                <Link to="/dashboard/tickets" className="absolute top-2 right-2 text-xl text-gray-500 ">
                                    <BiLinkExternal />
                                </Link>
                                <dt className="order-last text-lg font-medium text-gray-500">Tickets Created</dt>
                                <dd className="text-4xl font-extrabold text-primaryColorLight md:text-5xl">
                                    <CountUp end={totalTickets || 0} />
                                </dd>
                            </div>
                            <div className="relative flex flex-col rounded-lg border border-gray-300 px-4 py-8 text-center shadow-md hover:shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10">
                                <Link to="/dashboard/team" className="absolute top-2 right-2 text-xl text-gray-500">
                                    <BiLinkExternal />
                                </Link>
                                <dt className="order-last text-lg font-medium text-gray-500">Team Member</dt>
                                <dd className="text-4xl font-extrabold text-primaryColorLight md:text-5xl">
                                    <CountUp end={team?.length || 0} />
                                </dd>
                            </div>
                        </dl> :
                        <div class="p-4 mb-4 text-lg text-green-800 rounded-lg bg-green-50" role="alert">
                            You've generated <span className='text-primaryColor font-bold'>{totalLinksBySeller} </span> ticketing links so far. Keep up the great work and continue adding more tickets to meet growing demand and boost your sales!
                        </div>
                }
            </div>

            <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-8">

                <div className="lg:row-span-2 flex flex-col md:h-[378px]">
                    <div className="flex-grow block rounded-xl border border-gray-300 shadow-md hover:shadow-xl  transition hover:border-pink-500/10 hover:shadow-pink-500/10">
                        <div className="w-full h-full">
                            <div id="animation-carousel" className="relative w-full h-full" data-carousel="static">
                                <div className="overflow-hidden rounded-lg h-full" style={{ minHeight: '300px' }}>
                                    {displayEvents.map((item, index) => {
                                        const badgeInfo = getBadgeInfo(item.dateStart);
                                        return (
                                            <div
                                                key={index}
                                                className={`absolute w-full h-full transition-opacity duration-700 ease-in-out ${index === activeIndex ? 'opacity-100' : 'opacity-0'
                                                    }`}
                                                data-carousel-item={index === activeIndex ? 'active' : ''}
                                            >
                                                <img
                                                    src={item.image}
                                                    className="absolute block w-full h-full object-cover rounded-xl"
                                                    alt={item.name}
                                                />
                                                <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t rounded-xl from-black/70 via-black/50 to-transparent h-1/3 pointer-events-none"></div>
                                                <h2 className="absolute bottom-4 left-4 text-3xl font-bold text-white z-10">
                                                    {/* {item?.name ? formatName(item?.name) : ''} */}
                                                    {item?.name}
                                                </h2>
                                                {events.length && <div className={`absolute top-4 right-4 ${badgeInfo.color} text-white px-2 py-1 text-base font-bold rounded`}>
                                                    {badgeInfo.text}
                                                </div>}
                                            </div>
                                        );
                                    })}
                                </div>

                                {
                                    events.length > 1 ? (


                                        <button
                                            type="button"
                                            className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                                            data-carousel-prev
                                            onClick={prevSlide}
                                        >
                                            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                                                <svg className="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                                </svg>
                                                <span className="sr-only">Previous</span>
                                            </span>
                                        </button>
                                    ) : null
                                }

                                {
                                    events.length > 1 ? (
                                        <button
                                            type="button"
                                            className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                                            data-carousel-next
                                            onClick={nextSlide}
                                        >
                                            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                                                <svg className="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                                </svg>
                                                <span className="sr-only">Next</span>
                                            </span>
                                        </button>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:row-span-2 flex flex-col md:h-[378px]">
                    <div className="  rounded-xl border md:h-[378px]  p-4 border-gray-300 shadow-md hover:shadow-xl transition hover:border-pink-500/10 hover:shadow-pink-500/10">
                        <div className=' flex flex-col'>
                            <div className="mb-4">
                                <h2 className="text-2xl font-semibold">
                                    {/* {user?.name.split(' ').map(part => part[0].toUpperCase() + part.slice(1)).join(' ')} */}
                                    {user?.name}
                                </h2>
                                <p className="text-xl text-gray-600">{user?.role === Roles.USER ? RolesUI.USER : RolesUI.SELLER}</p>
                                <div className='mt-4'>
                                    <div className='my-2'>
                                        <h4 className="text-lg font-semibold text-gray-700">Email</h4>
                                        <span className="text-lg text-gray-500">{user?.email}</span>
                                    </div>
                                    <div className='my-2'>
                                        <h4 className="text-lg font-semibold text-gray-700">Phone Number</h4>
                                        <span className="text-lg text-gray-500">{user?.phonNumber ? user?.phonNumber : '-'}</span>
                                    </div>
                                    <div className='my-2'>
                                        <h4 className="text-lg font-semibold text-gray-700">Supervisor</h4>
                                        <span className="text-lg text-gray-500">{user?.supervisor ? `${user?.supervisor.firstName} ${user?.supervisor.lastName}` : user?.name.split(' ').map(part => part[0].toUpperCase() + part.slice(1)).join(' ')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="md:mt-5 flex flex-col w-full">
                                <div className="relative">
                                    <button
                                        type="button"
                                        className={clsx(
                                            "w-full py-2.5 px-5 mb-2 text-sm font-medium text-white focus:outline-none rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100",
                                            user.role == Roles.USER ? 'bg-primaryColor' : 'bg-gray-400 cursor-not-allowed' // Disabled button styling
                                        )}
                                        onClick={user.role == Roles.USER ? openModal : null} // Only allow click if role is 'USER'
                                        disabled={user.role !== Roles.USER} // Disable if role is not 'USER'
                                    >
                                        <span className="text-base">Change Password</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:row-span-2 flex flex-col md:h-[378px]">
                    <div className="flex-grow block ">
                        <EventCalendar events={events} />
                    </div>
                </div>
            </div>


            <EditPassword
                isOpen={isModalOpen}
                onClose={closeModal}
            ></EditPassword>

        </div>

    )
}

export default Home