export const getToken = () => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user)  {
    return {
      user : user.token ,
      role : user.role ,
      name : user.name ,
      email: user.email,
      phonNumber : user.phonNumber
    } 
  }

  return null
}
