import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  changeFreePaymmentStatus,
  createPaymentLink,
  getLink,
  getPaymentInfo,
  getTicketsByLinkId
} from '../services/Redux/slices/LinksSlice'
import {
  addNewTicket,
  downloadTicketPdf
} from '../services/Redux/slices/tickets'
import PaymentButton from '../components/Shared/PaymentButton'
import { unwrapResult } from '@reduxjs/toolkit'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import Error from './Error'

const ParticipantForm = ({ participant, index, onChange, onRemove }) => (
  <div className='grid grid-cols-1 gap-6 md:grid-cols-3 items-center mb-4'>
    <div>
      <label className='block text-sm font-medium text-gray-700'>
        Participant Name
      </label>
      <input
        type='text'
        value={participant.name}
        onChange={(e) => onChange(index, 'name', e.target.value)}
        className='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
      />
    </div>
    <div>
      <label className='block text-sm font-medium text-gray-700'>
        Phone Number
      </label>
      <input
        type='text'
        value={participant.phone}
        onChange={(e) => onChange(index, 'phone', e.target.value)}
        className='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
      />
    </div>
    <button
      type='button'
      onClick={() => onRemove(index)}
      className='mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
    >
      Remove
    </button>
  </div>
)

function GeneratedLink() {
  const { id } = useParams()
  const { link, loading, error, paymentLink, paymentStatus, tickets, statusCode } =
    useSelector((state) => state.links)
  const dispatch = useDispatch()
  const [participants, setParticipants] = useState([{ name: '', phone: '' }])
  const [totalAmount, setTotalAmount] = useState(0)
  const [validationError, setValidationError] = useState('')
  const [exceededError, setExceedError] = useState('')
  const [freePaymentDone, setFreePaymentDone] = useState(false)
  const [onSubmit, setOnSubmit] = useState(false);

  useEffect(() => {
    dispatch(getLink(id))
  }, [dispatch, id])

  useEffect(() => {
    if (link) {
      dispatch(getPaymentInfo(id))
      dispatch(getTicketsByLinkId(id))
    }
  }, [dispatch, id, freePaymentDone, link])

  useEffect(() => {
    if (paymentStatus === 'completed') {
      link.participants.forEach((participant) => {
        dispatch(
          addNewTicket({
            link: link?._id,
            buyer: link?.BuyerName,
            buyerEmail: link?.BuyerEmail,
            event: link?.event._id,
            type: link.ticketType._id,
            numTel: link.numTel,
            participant: participant.name,
            participantPhone: participant.phone
          })
        )
      })
    }
  }, [paymentStatus, link, dispatch])

  const addParticipant = useCallback(() => {
    if (participants.length < link.nbTicketMax) {
      setParticipants([...participants, { name: '', phone: '' }])
    } else {
      setExceedError(
        `Number of allowed participants exceeded. Maximum allowed is ${link.nbTicketMax}.`
      )
    }
  }, [participants, link?.nbTicketMax])

  const removeParticipant = useCallback(
    (index) => {
      if (participants.length > 1) {
        const updatedParticipants = [...participants]
        updatedParticipants.splice(index, 1)
        setParticipants(updatedParticipants)
        setExceedError('')
      }
    },
    [participants]
  )

  const handleParticipantChange = useCallback(
    (index, field, value) => {
      const updatedParticipants = [...participants]
      updatedParticipants[index][field] = value
      setParticipants(updatedParticipants)
    },
    [participants]
  )

  const validateParticipants = useCallback(() => {
    for (const participant of participants) {
      if (!participant.name || !participant.phone) {
        setValidationError(
          'All participant names and phone numbers must be filled.'
        )
        return false
      }
    }

    const uniqueParticipants = new Set(
      participants.map(
        (participant) => `${participant.name}-${participant.phone}`
      )
    )

    if (uniqueParticipants.size !== participants.length) {
      setValidationError(
        'No two participants can have the same name and phone number.'
      )
      return false
    }

    setValidationError('')
    return true
  }, [participants])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateParticipants()) return

    const data = {
      amount: link.ticketType?.price * participants.length,
      id: link._id,
      participants: participants
    }
    setOnSubmit(true);
    try {
      if (link.ticketType?.price === 0) {
        for (const participant of participants) {
          const resultAction = await dispatch(
            addNewTicket({
              link: link?._id,
              buyer: link?.BuyerName,
              buyerEmail: link?.BuyerEmail,
              event: link?.event._id,
              type: link.ticketType._id,
              numTel: link.numTel,
              participant: participant.name,
              participantPhone: participant.phone
            })
          )
          unwrapResult(resultAction)
        }
        const paymentStatusAction = await dispatch(
          changeFreePaymmentStatus(link._id)
        )
        unwrapResult(paymentStatusAction)
        setFreePaymentDone(true)
      } else {
        const paymentLinkAction = await dispatch(createPaymentLink(data))
        unwrapResult(paymentLinkAction)
      }

      setTotalAmount(link.ticketType?.price * participants.length)
    } catch (error) {
      console.error('Failed to process the request:', error)
    } finally {
      setOnSubmit(false); // End loading
    }
  }

  const handleClick = (id) => {
    dispatch(downloadTicketPdf(id))
  }

  const reloadPage = () => {
    window.location.reload()
  }

  const eventInfo = useMemo(() => {
    if (!link) return null

    return (
      <>
        {link.event.image && (
          <div className='relative mb-8'>
            <img
              src={link.event.image}
              alt={link.event.name}
              className='w-full aspect-w-1 object-cover rounded-lg shadow-sm'
            />
            <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4 rounded-b-lg'>
              <h1 className='text-xl font-bold text-white'>
                {link.event.name}
              </h1>
              <p className='text-md text-gray-200'>{link.event.venue}</p>
            </div>
          </div>
        )}
        <div className='mb-8'>
          <h2 className='text-xl font-semibold mb-2 text-gray-800'>
            Buyer Information
          </h2>
          <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Name
              </label>
              <input
                type='text'
                value={link.BuyerName}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Email
              </label>
              <input
                type='email'
                value={link.BuyerEmail}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
          </div>
        </div>
        <div className='mb-8'>
          <h2 className='text-xl font-semibold mb-2 text-gray-800'>
            Event Information
          </h2>
          <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Event Name
              </label>
              <input
                type='text'
                value={link.event.name}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Venue
              </label>
              <input
                type='text'
                value={link.event.venue}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Date Start
              </label>
              <input
                type='text'
                value={new Date(link.event.dateStart).toLocaleDateString()}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Date End
              </label>
              <input
                type='text'
                value={new Date(link.event.dateEnd).toLocaleDateString()}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
          </div>
        </div>
        <div className='mb-8'>
          <h2 className='text-xl font-semibold mb-2 text-gray-800'>
            Ticket Information
          </h2>
          <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Ticket Type
              </label>
              <input
                type='text'
                value={link.ticketType?.name}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Price
              </label>
              <input
                type='text'
                value={`${link.ticketType?.price} TND`}
                disabled
                className='mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md shadow-sm'
              />
            </div>
          </div>
        </div>
      </>
    )
  }, [link])
  if (loading) {
    return <LoadingSpinner></LoadingSpinner>
  }

  if (error) {
    return <Error statusCode={statusCode} />
  }

  //ticket generated
  return (
    <div className='container mx-auto p-4'>
      <div className='bg-white shadow-lg rounded-lg p-6 max-w-3xl mx-auto'>
        {loading && (
          <div className='text-center text-lg font-semibold text-blue-600'>
            Loading...
          </div>
        )}
        {/* {error && (
          <div className='text-center text-lg font-semibold text-red-600'>
            Error: {error}
          </div>
        )} */}

        {link &&
          !paymentLink &&
          paymentStatus !== 'completed' &&
          !freePaymentDone &&
          !link.freePaymentIsDone && (
            <>
              {eventInfo}
              <div className='my-2'>

                <div >
                  <div class="flex p-4 mb-4 text-sm text-white rounded-lg bg-primaryColorLight" role="alert">
                    <svg class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Info</span>
                    <div>
                      <span class="font-medium">Important Notice:</span>
                      <ul class="mt-1.5 list-disc list-inside">
                        <li>Ensure that you provide accurate and complete information when booking your ticket.</li>
                        <li>The event controller reserves the right to deny entry if your information is found to be incorrect.</li>
                        <li>Entry may be denied if you are under the influence of alcohol or other substances.</li>
                        <li>These measures are in place to ensure safety and a smooth experience for all attendees.</li>
                      </ul>
                    </div>
                  </div>
                </div>


                <h2 className='text-xl font-semibold mb-2 text-gray-800'>
                  Participant Information
                </h2>
                {participants.map((participant, index) => (
                  <ParticipantForm
                    key={index}
                    participant={participant}
                    index={index}
                    onChange={handleParticipantChange}
                    onRemove={removeParticipant}
                  />
                ))}
                {!exceededError && (
                  <button
                    type='button'
                    onClick={addParticipant}
                    className='inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                  >
                    Add Participant
                  </button>
                )}
                {exceededError && (
                  <div className='text-red-600 text-sm mt-2'>
                    {exceededError}
                  </div>
                )}
                {validationError && (
                  <div className='text-red-600 text-sm mt-2'>
                    {validationError}
                  </div>
                )}
              </div>
              <div className='flex justify-end'>
                <button
                  type='submit'
                  onClick={handleSubmit}
                  disabled={onSubmit}
                  className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-700 hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-900'
                >
                  {/* {link.ticketType?.price === 0
                    ? 'Get your tickets'
                    : 'Generate Payment Link'} */}
                  {onSubmit
                    ? 'Loading...'
                    : link.ticketType?.price === 0
                      ? 'Get your tickets'
                      : 'Generate Payment Link'}
                </button>
              </div>
            </>
          )}

        {paymentLink && paymentStatus !== 'completed' && (
          <div className='text-center'>
            <p className='text-xl font-semibold text-gray-800 mb-4'>
              Payment Link
            </p>
            <PaymentButton paymentLink={paymentLink} />
            <p className='text-md text-gray-600 mt-2'>
              Total Amount: {totalAmount} TND
            </p>
          </div>
        )}

        {(paymentStatus === 'completed' ||
          freePaymentDone ||
          link?.freePaymentIsDone) && (
            <div className='text-center'>
              <p className='text-xl font-semibold text-gray-800 mb-4'>
                Payment completed successfully! Here are your tickets:
              </p>
              <div className='flex flex-col items-center my-4'>
                <span> Don't see your tickets?</span>
                <button
                  type='button'
                  onClick={reloadPage}
                  className='mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-pink-700 hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-900'
                >
                  Reload Page to Get Tickets
                </button>
              </div>
              {tickets &&
                tickets.length > 0 &&
                tickets.map((ticket, index) => (
                  <div
                    key={index}
                    className='mb-4 p-4 border border-gray-200 rounded-lg shadow-sm'
                  >
                    <h3 className='text-lg font-semibold text-gray-800'>
                      Ticket for {ticket.participant}
                    </h3>
                    <p className='text-md text-gray-600'>
                      Phone: {ticket.participantPhone}
                    </p>
                    <button
                      type='button'
                      onClick={() => handleClick(ticket._id)}
                      className='mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-pink-700 hover:bg-pink-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600'
                    >
                      Download Ticket {index + 1}
                    </button>
                  </div>
                ))}
            </div>
          )}
      </div>
    </div>
  )
}

export default GeneratedLink
