import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const prefix = 'tickets'

export const getTickets = createAsyncThunk(
  'tickets',
  async ({ page,limit,buyerName,participant,participantPhone,eventId}, thunkApi) => {
    try {
      const response = await client.get(`${prefix}/get`, {
        params: {
          page,
          limit,
          buyerName,
          participant,
          participantPhone,
          eventId:eventId =='All'? undefined : eventId
        },
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const deleteTicketById = createAsyncThunk(
  'tickets/delete',
  async (ticketId, thunkApi) => {
    try {
      const response = await client.delete(`${prefix}/delete/${ticketId}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return ticketId
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const addNewTicket = createAsyncThunk(
  'tickets/add',
  async (data, thunkApi) => {
    try {
      const response = await client.post(`${prefix}/add`, data, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const updateTicket = createAsyncThunk(
  'ticket/update',
  async (data, thunkApi) => {
    try {
      const response = await client.put(
        `${prefix}/update/${data.id}`,
        { status: data.status },
        {
          headers: {
            Authorization: `Bearer ${getToken()?.user}`
          }
        }
      )
      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const downloadTicketPdf = createAsyncThunk(
  'tickets/downloadTicketPdf',
  async (ticketId, thunkApi) => {
    try {
      const response = await client.get(`${prefix}/${ticketId}/download`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` },
        responseType: 'blob'
      })

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `ticket_${ticketId}.pdf`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      return ticketId
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const getTicketInfo = createAsyncThunk(
  'tickets/getTicketInfo',
  async (ticketId, thunkApi) => {
    try {
      const response = await client.get(`${prefix}/get/${ticketId}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const LinksSlice = createSlice({
  name: 'tickets',
  initialState: {
    tickets: [],
    ticketsWithoutPagination:[],
    error: '',
    success: false,
    currentPage:1,
    totalPages:0,
    totalTickets:0,
    scannedTicket: null,
    loading: false
  },
  reducers: {
    resetTickets: (state) => {
      state.error = ''
      state.success = false
      state.tickets = []
      state.ticketsWithoutPagination=[]
      state.currentPage=1
      state.totalPages=0
      state.totalTickets=0
      state.scannedTicket = null
      state.loading = false
    },
    resetScannedTicket: (state) => {
      state.scannedTicket = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTickets.fulfilled, (state, action) => {
      state.tickets = action.payload.tickets
      state.currentPage=action.payload.currentPage
      state.totalPages=action.payload.totalPages
      state.totalTickets=action.payload.totalTickets
      state.ticketsWithoutPagination=action.payload.ticketsWithoutPagination
      state.success = true
      state.loading = false
    })
    builder.addCase(getTickets.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
    builder.addCase(getTickets.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(downloadTicketPdf.fulfilled, (state, action) => {
      state.success = true
    })
    builder.addCase(addNewTicket.fulfilled, (state, action) => {
      state.success = true
      state.tickets.push(action.payload)
      state.tickets.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      )
    })
    builder.addCase(addNewTicket.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(deleteTicketById.fulfilled, (state, action) => {
      state.success = true
      state.tickets = state.tickets.filter(
        (ticket) => ticket._id !== action.payload
      )
    })
    builder.addCase(deleteTicketById.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(updateTicket.fulfilled, (state, action) => {
      state.success = true
      state.tickets = state.tickets.map((ticket) =>
        ticket._id === action.payload._id ? action.payload : ticket
      )
    })
    builder.addCase(updateTicket.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(getTicketInfo.fulfilled, (state, action) => {
      state.scannedTicket = action.payload
      state.loading= false
    })
    builder.addCase(getTicketInfo.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getTicketInfo.rejected, (state, action) => {
      state.loading = false
    })
   
  }
})

export const { resetTickets ,resetScannedTicket } = LinksSlice.actions
export default LinksSlice.reducer
