import React, { Children } from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'

import Sidebar from '../components/Layout/SideBar/SideBar'
import useToggle from '../hooks/useToggle'
import MobileHeader from '../components/Layout/MobileHeader'
import MobileSidebar from '../components/Layout/MobileSidebar'
import { useMediaQuery } from '../hooks/useMediaQuery'
import clsx from 'clsx'

function Dashboard() {
  const [sidebarOpen, toggleSidebar] = useToggle(false, true, 'mineclap:sidebar')
  const mobile = useMediaQuery('(min-width: 320px) and (max-width: 768px)')

  const [mobileSidebar, toggleMobileSidebar] = useToggle(false)
  console.log('Mobile View:', mobile)
  console.log('Mobile Sidebar Open:', mobileSidebar)
  return (
    <div className='flex min-h-screen flex-col'>
      <div className={clsx('flex flex-1 flex-col md:flex-row')}>
        {!mobile ? (
          <Sidebar open={sidebarOpen} onToggleSideBar={toggleSidebar} />
        ) : (
          <MobileHeader onToggle={toggleMobileSidebar} />
        )}
        <main className='relative flex-1 overflow-auto bg-mainWhiteBg'>
          {mobile && <MobileSidebar open={mobileSidebar} onToggleMobileSideBar={toggleMobileSidebar}  />}
          <div className='max-h-0 p-6'>{<Outlet></Outlet>}</div>
        </main>
      </div>
    </div>
  )
}

export default Dashboard
