import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLink, getLinks } from '../../../../services/Redux/slices/LinksSlice'
import { format, parseISO } from 'date-fns'

const AddLink = ({ isOpen, onClose, columns, events, types }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.links)
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})

  const handleInputChange = (e, key) => {
    let value = e.target.value
    setFormData({ ...formData, [key]: value })
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      buyerName: '',
      buyerEmail: '',
      numTel: '',
      event: prevFormData.event || '', // Preserve existing value if available
      ticketType: prevFormData.ticketType || '' // Preserve existing value if available
    }))
  }, [isOpen])

  const validateForm = () => {
    let newErrors = {}
    let isValid = true
    let currentDate = new Date()

    columns.forEach((column) => {
      if (column.required && !formData[column.key]) {
        newErrors[column.key] = `${column.label} is required`
        isValid = false
      }
      if (column.key === 'ExpiryDate' && formData[column.key]) {
        let expiryDate = parseISO(formData[column.key])
        if (expiryDate <= currentDate) {
          newErrors[
            column.key
          ] = `${column.label} must be greater than the current date`
          isValid = false
        }
      }
    })

    setErrors(newErrors)
    return isValid
  }

  // const handleAddLink = () => {
  //   if (validateForm()) {
  //     dispatch(addLink(formData))
  //     dispatch(getLinks())
  //     onClose()
  //   }
  // }


  const handleAddLink = () => {
    if (validateForm()) {
      dispatch(addLink(formData))
        .then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
            dispatch(getLinks())
            onClose()
          }
        })
    }
  }

  const handleClose = () => {
    if (!loading) {
      onClose()
    }
  }




  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Add Link</h2>

        {columns.map((column) => (
          <div key={column.key} className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              {column.label}
              {column.required && <span className='text-red-500 ml-1'>*</span>}
            </label>
            {column.key === 'event' ? (
              <select
                value={formData.event || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                disabled={loading}
              >
                <option value=''>Select {column.label}</option>
                {Array.isArray(events) &&
                  events.map((event) => (
                    <option key={event._id} value={event._id}>
                      {event.name}
                    </option>
                  ))}
              </select>
            ) : column.key === 'ticketType' ? (
              <select
                value={formData.ticketType || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                disabled={loading}
              >
                <option value=''>Select {column.label}</option>
                {Array.isArray(types) &&
                  types.map((type) => (
                    <option key={type._id} value={type._id}>
                      {type.name}
                    </option>
                  ))}
              </select>
            ) : (
              <input
                type={column.type}
                placeholder={column.label}
                value={formData[column.key] || ''}
                min={1}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                disabled={loading}
              />
            )}
            {errors[column.key] && (
              <span className='text-red-500 text-sm'>{errors[column.key]}</span>
            )}
          </div>
        ))}
        {Object.keys(errors).length > 0 && (
          <p className='text-red-500 text-sm mb-4'>
            Please check the highlighted fields for errors.
          </p>
        )}
        <div className='flex justify-end'>
          <button
            onClick={handleClose}
            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleAddLink}
            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
            disabled={loading}
          >
            {loading ? 'Adding...' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddLink
