import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import SidebarLink from './SideBarLink'
import NavBurger from './NavBurger'
import navMenuLinksData from './navMenuLinks'
// You may need to replace this with an alternative image component if you're not using Next.js
import NavMenuLinksWrapper from './navMenuLinksWrapper'
import logo from '../../../assets/images/logo.svg'
import { useSelector } from 'react-redux'
const Sidebar = ({ open, onToggleSideBar }) => {
  const [activePage, setActivePage] = useState('')

  useEffect(() => {
    const pathname = window.location.pathname
    setActivePage(pathname)
    console.log(activePage)
  }, [activePage])

  const { user } = useSelector((state) => state.auth); 
  const filteredMenuLinks = navMenuLinksData.filter((item) =>
    item.roles.includes(user.role)
  );

  return (
    <div
      className={clsx(
        'z-10 hidden flex-col md:flex h-[100vh] ',
        open && 'w-[19vw] min-w-[220px] max-w-[270px]',
        !open && 'w-[5vw] max-w-[70px]',
        'bg-primaryColor shadow-lg shadow-gray-100 transition-all'
      )}
    >
      <div
        className={clsx(
          'flex h-[72px] items-center justify-between gap-3',
          !open ? 'justify-between px-[0]' : 'justify-center pl-[22px]'
        )}
      >
        {open && (
          <div role='banner' className='flex '>
            <img className='w-[80px]' src={logo} />
          </div>
        )}
        <div className={clsx('h-[30px]', !open ? 'mx-auto' : 'mr-4')}>
          <div className={clsx('h-[30px]', !open && 'rotate-180')}>
            <NavBurger
              aria-label={open ? 'Close Sidebar' : 'Open Sidebar'}
              onClick={onToggleSideBar}
            />
          </div>
        </div>
      </div>
      <div className='mt-16 flex flex-col'>
        <nav
          aria-label='Primary Site Navigation'
          className={clsx(
            'mt-16 flex flex-col text-lg font-light text-slate-600',
            !open &&
              '[&_a]:grid [&_a]:place-items-center [&_a]:px-0 [&_a_>_.icon]:m-0 [&_a_>_.label]:hidden'
          )}
        >
          {filteredMenuLinks.map((navLink) => {
            return (
              <NavMenuLinksWrapper
                tooltipLabel={navLink.label}
                hideTooltip={open}
                key={navLink.href}
              >
                <SidebarLink
                  isActive={activePage === navLink.href}
                  icon={navLink.icon}
                  label={navLink.label}
                  href={navLink.href}
                  setActivePage={setActivePage}
                />
              </NavMenuLinksWrapper>
            )
          })}
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
