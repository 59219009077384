import React from 'react';

const PaymentButton = ({ paymentLink }) => {
  const handlePaymentClick = () => {
    console.log(paymentLink);
    window.open(paymentLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <button
      onClick={handlePaymentClick}
      className='text-white bg-blue-600 px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
    >
      Pay Now
    </button>
  );
};

export default PaymentButton;
