import { format, parseISO } from 'date-fns'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateTeamMember } from '../../../../services/Redux/slices/TeamSlice'
import { updateType } from '../../../../services/Redux/slices/TicketTypes'

// Helper function to get nested values
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((o, i) => (o ? o[i] : ''), obj);
}

// Helper function to set nested values (returns a new object with updated value)
const setNestedValue = (obj, path, value) => {
  const keys = path.split('.');
  const lastKey = keys.pop();
  const newObj = { ...obj }; // Create a shallow copy of the object
  const deepRef = keys.reduce((o, k) => {
    o[k] = { ...o[k] }; // Create shallow copies of nested objects
    return o[k];
  }, newObj);
  deepRef[lastKey] = value;
  return newObj; // Return the new object with updated values
}

const EditMember = ({ isOpen, onClose, memberSelected, columns }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(memberSelected);
  const fileInputRef = useRef(null);

  // Handle input change for all types of input
  const handleInputChange = (e, key) => {
    let value = e.target.value;
    const updatedFormData = setNestedValue(formData, key, value);
    setFormData(updatedFormData);
  }

  // Handle file upload input
  const handleFileChange = (e, key) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const updatedFormData = setNestedValue(formData, key, event.target.result);
      setFormData(updatedFormData);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  

  const handleEditEvent = () => {
    dispatch(updateTeamMember(formData));
    onClose();
  }

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Edit Member Info</h2>

        {columns.map((column) => (
          <div key={column.key} className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              {column.label}
              {column.required && <span className='text-red-500 ml-1'>*</span>}
            </label>

            {column.type === 'select' ? (
              <select
                value={getNestedValue(formData, column.key) || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
              >
                <option value=''>Select {column.label}</option>
                {column.options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : column.type === 'file' ? (
              <div>
                <input
                  ref={fileInputRef}
                  type='file'
                  accept='image/*'
                  onChange={(e) => handleFileChange(e, column.key)}
                  className='border border-gray-300 rounded-md p-2 w-full'
                />
                {getNestedValue(formData, column.key) && (
                  <img
                    src={getNestedValue(formData, column.key)}
                    alt='Preview'
                    className='mt-2 rounded-md max-h-40'
                  />
                )}
              </div>
            ) : (
              <input
                type={column.type || 'text'}
                placeholder={column.label}
                value={getNestedValue(formData, column.key) || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                disabled={column.key === 'email.address'} // Disable Identifier field
                readOnly={column.key === 'email.address'}  // Make Identifier read-only
              />
            )}
          </div>
        ))}

        <div className='flex justify-end'>
          <button
            onClick={onClose}
            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Cancel
          </button>
          <button
            onClick={handleEditEvent}
            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditMember;
