import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTeamMember } from '../../../../services/Redux/slices/TeamSlice';

const AddMember = ({ isOpen, onClose, columns}) => {
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.teams)
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  
  const fileInputRef = useRef(null);

  // Function to generate a random password
  const generatePassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%';
    const passwordLength = 8; // You can change the length as needed
    let password = '';
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    return password;
  };

  const handleInputChange = (e, key) => {
    let value = e.target.value;
    setFormData({ ...formData, [key]: value });
  };

  // Function to set generated password
  const handleGeneratePassword = () => {
    const generatedPassword = generatePassword();
    setFormData({ ...formData, "password.password" : generatedPassword }); // Ensure correct setting of password
  };

  
  const handleAddMember = () => {
    dispatch(addNewTeamMember(formData))
    .then((result) => {
      if (result.meta.requestStatus === 'fulfilled') {
         onClose()
         setFormData({})
      }
    })
  }
  
  const handleClose = () => {
    if (!loading) {
      onClose()
    }
  }
  

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${isOpen ? 'flex' : 'hidden'}`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Add Member</h2>
        {columns.map((column) => (
          <div key={column.key} className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              {column.label}
              {column.required && <span className='text-red-500 ml-1'>*</span>}
            </label>
            {column.type === 'select' ? (
              <select
                value={formData[column.key] || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                disabled={loading}
              >
                <option value=''>Select {column.label}</option>
                {column.options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : column.type === 'file' ? (
              <div>
                <input
                  ref={fileInputRef}
                  type='file'
                  accept='image/*'
                  onChange={(e) => {
                    handleInputChange(e, column.key);
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      setFormData({
                        ...formData,
                        [column.key]: event.target.result,
                      });
                    };
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                  }}
                  className='border border-gray-300 rounded-md p-2 w-full'
                  disabled={loading}
                />
                {formData[column.key] && (
                  <img
                    src={formData[column.key]}
                    alt='Preview'
                    className='mt-2 rounded-md max-h-40'
                  />
                )}
              </div>
            ) : (
              <input
                type={column.type}
                placeholder={column.label}
                value={formData[column.key] || ''}
                min={0}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                disabled={loading}
              />
            )}

            {column.key === 'password.password' && (
              <button
                type='button'
                onClick={handleGeneratePassword}
                className='mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
                disabled={loading}
              >
                Generate Password
              </button>
            )}
          </div>
        ))}

        <div className='flex justify-end'>
          <button
            onClick={handleClose}
            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Cancel
          </button>
          <button 
            disabled={loading}
            onClick={handleAddMember}  
            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
          >
            {loading ? 'Loading...': 'Add' } 
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMember;



















// import React, { useRef, useState } from 'react'
// import { useDispatch } from 'react-redux'

// const AddMember = ({ isOpen, onClose, columns, events, types }) => {

//   const dispatch = useDispatch()
//   const [formData, setFormData] = useState({})
//   const [errors, setErrors] = useState({})

//   const fileInputRef = useRef(null)


//   // Function to generate a random password
//   const generatePassword = () => {
//     const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%';
//     const passwordLength = 8; // You can change the length as needed
//     let password = '';
//     for (let i = 0; i < passwordLength; i++) {
//       const randomIndex = Math.floor(Math.random() * chars.length);
//       password += chars[randomIndex];
//     }
//     return password;
//   };

//   const handleInputChange = (e, key) => {
//     let value = e.target.value
//     setFormData({ ...formData, [key]: value })
//   }


//   // Function to set generated password
//   const handleGeneratePassword = () => {
//     const generatedPassword = generatePassword()
//     setFormData({ ...formData, 'password.password': generatedPassword }) // Set the password in formData
//   }


//   return (
//     <div
//       className={`fixed inset-0 z-50 overflow-auto ${isOpen ? 'flex' : 'hidden'
//         }`}
//     >
//       <div className='fixed inset-0 bg-black bg-opacity-50'></div>
//       <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
//         <h2 className='text-lg font-semibold mb-4'>Add Ticket Type</h2>

//         {columns.map((column) => (
//           <div key={column.key} className='mb-4'>
//             <label className='block text-sm font-medium text-gray-700 my-3'>
//               {column.label}
//               {column.required && <span className='text-red-500 ml-1'>*</span>}
//             </label>
//             {column.type === 'select' ? (
//               <select
//                 value={formData[column.key] || ''}
//                 onChange={(e) => handleInputChange(e, column.key)}
//                 className='border border-gray-300 rounded-md p-2 w-full'
//               >
//                 <option value=''>Select {column.label}</option>
//                 {column.options.map((option) => (
//                   <option key={option.id} value={option.id}>
//                     {option.name}
//                   </option>
//                 ))}
//               </select>
//             ) : column.type === 'file' ? (
//               <div>
//                 <input
//                   ref={fileInputRef}
//                   type='file'
//                   accept='image/*'
//                   onChange={(e) => {
//                     handleInputChange(e, column.key)
//                     const file = e.target.files[0]
//                     const reader = new FileReader()
//                     reader.onload = (event) => {
//                       setFormData({
//                         ...formData,
//                         [column.key]: event.target.result
//                       })
//                     }
//                     if (file) {
//                       reader.readAsDataURL(file)
//                     }
//                   }}
//                   className='border border-gray-300 rounded-md p-2 w-full'
//                 />
//                 {formData[column.key] && (
//                   <img
//                     src={formData[column.key]}
//                     alt='Preview'
//                     className='mt-2 rounded-md max-h-40'
//                   />
//                 )}
//               </div>
//             ) : (
//               <input
//                 type={column.type}
//                 placeholder={column.label}
//                 value={formData[column.key] || ''}
//                 min={0}
//                 onChange={(e) => handleInputChange(e, column.key)}
//                 className='border border-gray-300 rounded-md p-2 w-full'
//               />
               
//                {column.key === 'password.password' && (
//               <button
//                 type='button'
//                 onClick={handleGeneratePassword}
//                 className='mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600'
//               >
//                 Generate Password
//               </button>
//             )}
//             )}
//           </div>
//         ))}

//         <div className='flex justify-end'>
//           <button
//             onClick={onClose}
//             className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
//           >
//             Cancel
//           </button>
//           <button

//             className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
//           >
//             Add
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default AddMember