export const Roles = Object.freeze({
    USER:"USER",
    SUPER_ADMIN:"SUPER_ADMIN",
    SELLER:"SELLER",
    CONTROLLER:"CONTROLLER"
});
  
export const RolesUI = Object.freeze({
    USER:"Manger",
    SUPER_ADMIN:"SUPER_ADMIN",
    SELLER:"Seller",
    CONTROLLER:"Controller"
});