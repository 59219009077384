import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

export const getMyTeamMembers = createAsyncThunk(
  'teamMember/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get('/get-team-members', {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      return response.data
    } catch (error) {
      console.log('error', error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const addNewTeamMember = createAsyncThunk(
  'teamMember/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await client.post('/add-team-member', data, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      return response.data
    } catch (error) {
      console.log('error', error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteTeamMember = createAsyncThunk(
  'teamMember/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await client.delete(`/delete-team-member/${id}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      return response.data
    } catch (error) {
      console.log('error', error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

export const updateTeamMember = createAsyncThunk(
  'teamMember/update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await client.put(
        `/update-member-info/${data._id}`,
        data,
        {
          headers: { Authorization: `Bearer ${getToken()?.user}` }
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

export const TeamSlice = createSlice({
  name: 'team',
  initialState: {
    team: [],
    error: null,
    loading: false
  },
  reducers: {
    resetTeam: (state) => {
      state.error = null
      state.team = []
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyTeamMembers.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getMyTeamMembers.fulfilled, (state, { payload }) => {
      state.team = payload
      state.loading = false
    })
    builder.addCase(getMyTeamMembers.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.team = []
    })
    builder.addCase(addNewTeamMember.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addNewTeamMember.fulfilled, (state, { payload }) => {
      state.team = [...state.team, payload]
      state.loading = false
    })
    builder.addCase(addNewTeamMember.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
    builder.addCase(deleteTeamMember.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteTeamMember.fulfilled, (state, { payload }) => {
      state.loading = false
      state.team = state.team.filter((member) => member._id !== payload)
    })
    builder.addCase(deleteTeamMember.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
    builder.addCase(updateTeamMember.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateTeamMember.fulfilled, (state, { payload }) => {
      state.loading = false
      state.team = state.team.map((member) =>
        member._id === payload._id ? payload : member
      )
    })
    builder.addCase(updateTeamMember.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
    })
  }
})

export const { resetTeam } = TeamSlice.actions
export default TeamSlice.reducer
