import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteEvent,
  getEvents,
  updateEvent
} from '../services/Redux/slices/EventsSlice'
import { format, parseISO } from 'date-fns'
import AddEvent from '../components/Core/CRUD/Add/AddEvent'
import EditEventModal from '../components/Core/CRUD/Edit/EditEvent'
import Crop from '../components/Core/CRUD/Add/Crop'
import LoadingSpinner from '../components/Layout/LoadingSpinner'

function Events() {
  const { events, loading } = useSelector((state) => state.events)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvents())
  }, [dispatch])

  const columns = [
    { label: 'Event Name', key: 'name' },
    { label: 'Venue', key: 'venue' },
    {
      label: 'Start date',
      key: 'dateStart',
      render: (row) => {
        if (!row.dateStart) {
          return 'No start date'
        }
        const date = parseISO(row.dateStart)
        return isNaN(date) ? 'Invalid date' : format(date, 'dd/MM/yyyy HH:mm')
      }
    },
    {
      label: 'End date',
      key: 'dateEnd',
      render: (row) => {
        if (!row.dateEnd) {
          return 'No end date'
        }
        const date = parseISO(row.dateEnd)
        return isNaN(date) ? 'Invalid date' : format(date, 'dd/MM/yyyy HH:mm')
      }
    }
  ]

  const toAddColumns = [
    { label: 'Event Name', key: 'name', type: 'text', required: true },
    { label: 'Venue', key: 'venue', type: 'text', required: true },
    { label: 'Street', key: 'rue', type: 'text', required: true },
    { label: 'City', key: 'ville', type: 'text', required: true },
    { label: 'Region', key: 'region', type: 'text', required: true },
    { label: 'Country', key: 'pays', type: 'text', required: true },
    { label: 'Postal Code', key: 'codePostale', type: 'text' },
    {
      label: 'Start Date',
      key: 'dateStart',
      type: 'datetime-local',
      required: true
    },
    {
      label: 'End Date',
      key: 'dateEnd',
      type: 'datetime-local',
      required: true
    },
    { label: 'Promoter', key: 'promoter', type: 'text', required: true },
    {
      label: 'Promoter Phone',
      key: 'promoterPhone',
      type: 'text',
      required: true
    },

    { label: 'Image', key: 'image', type: 'file', required: true }
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const addItem = (formData) => {
    console.log('Adding item:', formData)
    // Add your logic to add the item here
  }
  const handleEdit = async (updatedEvent) => {
    setIsEditModalOpen(false)
  }

  const handleEditClick = (event) => {
    setSelectedEvent(event)
    console.log(selectedEvent)
    setIsEditModalOpen(true)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium'>Events</p>
        <button
          onClick={openModal}
          className='bg-primaryColor p-2 rounded-sm text-white px-3 font-medium'
        >
          Add Event
        </button>
        <AddEvent
          columns={toAddColumns}
          isOpen={isModalOpen}
          onClose={closeModal}
          onAdd={addItem}
        />
      </div>
      <div className='my-8'>
        <DataTable
          columns={columns}
          data={events}
          onEditClick={handleEditClick}
          onDeleteClick={(event) => dispatch(deleteEvent(event))}
          toDeleteEvent={true}
        ></DataTable>
      </div>
      {isEditModalOpen && (
        <EditEventModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEdit}
          event={selectedEvent}
          columns={toAddColumns}
        />
      )}
    </div>
  )
}

export default Events
