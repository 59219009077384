import {
  BiHomeAlt,
  BiGroup,
  BiNote,
  BiErrorCircle,
  BiBook,
  BiFile,
  BiCalendarEvent,
  BiCalendar,
  BiUser,
  BiLogOut,
  BiLogOutCircle,
} from 'react-icons/bi'
import { IoSchoolOutline } from 'react-icons/io5'
import { Roles } from '../../../enum'
const navMenuLinksData = [
  {
    icon: BiHomeAlt,
    label: 'Dashboard',
    href: '/dashboard',
    roles: [Roles.USER, Roles.SELLER],
  },
  {
    icon: BiCalendarEvent,
    label: 'Events',
    href: '/dashboard/events',
    roles: [Roles.USER],
  },
  {
    icon: BiFile,
    label: 'TicketTypes',
    href: '/dashboard/ticketTypes',
    roles: [Roles.USER],
  },
  {
    icon: BiBook,
    label: 'Links',
    href: '/dashboard/links',
    roles: [Roles.USER, Roles.SELLER],
  },
  {
    icon: BiNote,
    label: 'Tickets',
    href: '/dashboard/tickets',
    roles: [Roles.USER],
  },
  {
    icon: BiGroup,
    label: 'Team',
    href: '/dashboard/team',
    roles: [Roles.USER],
  },
  {
    icon: BiLogOutCircle,
    label: 'Logout',
    href: '/dashboard/logout',
    roles: [Roles.USER ,Roles.SELLER ],
  }
]

export default navMenuLinksData
