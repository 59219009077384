import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Roles } from '../../../enum'
import { downloadTicketPdf } from '../../../services/Redux/slices/tickets'
import PasswordModal from './passwordModel'

const DataTable = ({ data, columns, onEditClick, onDeleteClick, disabled = false , toDeleteEvent }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [ToDelete, setToDelete] = useState(null);
  const handleClick = (id) => {
    dispatch(downloadTicketPdf(id))
  }
  const handleDeleteClick = (id) => {
    setToDelete(id);
    setIsPasswordModalOpen(true);
  };
  const generateTableHeaders = () => {
    return columns.map((column, index) => (
      <th
        key={index}
        className='px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3'
      >
        {column.label}
      </th>
    ))
  }

  const getNestedValue = (obj, path) => {
    return path
      .split('.')
      .reduce(
        (acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : ''),
        obj
      )
  }
  const handlePasswordConfirm = () => {
    onDeleteClick(ToDelete)
    setIsPasswordModalOpen(false);
  };
  const generateTableRows = () => {
    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {columns.map((column, columnIndex) => (
          <td
            key={columnIndex}
            className='px-2 py-1 whitespace-nowrap md:px-6 md:py-4 ' style={{ fontSize: 'smaller' }}

          >
            {column.isBoolean ? (
              row[column.key] ? (
                'Yes'
              ) : (
                'No'
              )
            ) : column.key === 'pdf' ? (
              <button
                className='underline text-blue-600'
                onClick={() => handleClick(row._id)}
              >
                ticket.pdf
              </button>
            ) : column.render ? (
              column.render(row)
            ) : (
              getNestedValue(row, column.key)
            )}
          </td>
        ))}


        {
          user.role === Roles.USER &&
          <td className='px-2 py-1 whitespace-nowrap md:px-6 md:py-4'>
            <div className='flex space-x-2'>
              {!disabled && <FaEdit
                className='text-blue-500 cursor-pointer'
                onClick={() => onEditClick(row)}

              />}

              <FaTrash
                className='text-red-500 cursor-pointer'
                onClick={() => handleDeleteClick(row._id)}
              />
            </div>
          </td>
        }

      </tr>
    ))
  }
  return (
    <div className='overflow-x-auto max-h-[80vh] mt-3'>
      <table className='min-w-full divide-y divide-gray-200'>
        <thead className='bg-gray-50'>
          <tr>
            {generateTableHeaders()}
            {
              user?.role === Roles.USER &&
              <th className='px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:px-6 md:py-3'>
                Actions
              </th>
            }

          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {generateTableRows()}
        </tbody>
      </table>
      <PasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        onConfirm={handlePasswordConfirm}
        toDeleteEvent={toDeleteEvent}
      />
    </div>
  )
}

export default DataTable
