import { format, parseISO } from 'date-fns'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateType } from '../../../../services/Redux/slices/TicketTypes'

const EditTicketTypeModal = ({ isOpen, onClose, type, columns }) => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState(type)
  const fileInputRef = useRef(null)

  const handleInputChange = (e, key, nestedKey) => {
    if (e.target.type === 'file') {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        if (nestedKey) {
          setFormData({
            ...formData,
            [key]: { ...formData[key], [nestedKey]: event.target.result }
          })
        } else {
          setFormData({ ...formData, [key]: event.target.result })
        }
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    } else {
      let value = e.target.value
      if (nestedKey) {
        setFormData({
          ...formData,
          [key]: { ...formData[key], [nestedKey]: value }
        })
      } else {
        setFormData({ ...formData, [key]: value })
      }
    }
  }

  const handleEditEvent = () => {
    dispatch(updateType(formData))
    onClose()
  }

  const addressFields = ['rue', 'ville', 'region', 'pays', 'codePostale']

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Edit Ticket Type</h2>

        {columns
          .filter((column) => !addressFields.includes(column.key))
          .map((column) => (
            <div key={column.key} className='mb-4'>
              <label className='block text-sm font-medium text-gray-700 my-3'>
                {column.label}
                {column.required && (
                  <span className='text-red-500 ml-1'>*</span>
                )}
              </label>
              {column.type === 'select' ? (
                <select
                  value={formData[column.key] || ''}
                  onChange={(e) => handleInputChange(e, column.key)}
                  className='border border-gray-300 rounded-md p-2 w-full'
                >
                  <option value=''>Select {column.label}</option>
                  {column.options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : column.type === 'file' ? (
                <div>
                  <input
                    ref={fileInputRef}
                    type='file'
                    accept='image/*'
                    onChange={(e) => {
                      handleInputChange(e, column.key)
                      const file = e.target.files[0]
                      const reader = new FileReader()
                      reader.onload = (event) => {
                        setFormData({
                          ...formData,
                          [column.key]: event.target.result
                        })
                      }
                      if (file) {
                        reader.readAsDataURL(file)
                      }
                    }}
                    className='border border-gray-300 rounded-md p-2 w-full'
                  />
                  {formData[column.key] && formData[column.key].length > 0 && (
                    <img
                      src={formData[column.key]}
                      alt='Preview'
                      className='mt-2 rounded-md max-h-40'
                    />
                  )}
                </div>
              ) : (
                <input
                  type={column.type}
                  placeholder={column.label}
                  value={formData[column.key] || ''}
                  min={0}
                  onChange={(e) => handleInputChange(e, column.key)}
                  className='border border-gray-300 rounded-md p-2 w-full'
                />
              )}
            </div>
          ))}

        <div className='flex justify-end'>
          <button
            onClick={onClose}
            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Cancel
          </button>
          <button
            onClick={handleEditEvent}
            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditTicketTypeModal
