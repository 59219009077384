import React from 'react'

const LoadingSpinner = () => {
  return (
    <div className='flex items-center justify-center h-[80vh]'>
      <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900'></div>
    </div>
  )
}

export default LoadingSpinner
