import axios from 'axios'

class ApiClient {
  constructor(baseURL) {
    if (!baseURL) {
      throw new Error('Base URL not defined')
    }

    this.axiosInstance = axios.create({
      baseURL
    })

    this.token = null

    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Include token in headers if available
        if (this.token) {
          config.headers.Authorization = `Bearer ${this.token}`
        }
        // Log or modify the request as needed
        console.log('Request:', config)
        return config
      },
      (error) => {
        console.error('Request error:', error)
        return Promise.reject(error)
      }
    )

    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Log or modify the response data as needed
        console.log('Response:', response)
        return response
      },
      (error) => {
        console.error('Response error:', error)
        return Promise.reject(error)
      }
    )
  }

  async request(config) {
    return this.axiosInstance.request(config)
  }

  async get(url, config) {
    return await this.axiosInstance.get(url, config)
  }

  async post(url, data, config) {
    return this.request({ ...config, method: 'POST', url, data })
  }

  async put(url, data, config) {
    return this.request({ ...config, method: 'PUT', url, data })
  }

  async delete(url, config) {
    return this.request({ ...config, method: 'DELETE', url })
  }
  async postForm(url, data, config) {
    return this.request({
      ...config,
      method: 'POST',
      url,
      data
    })
  }

  async getToken(authUrl, credentials) {
    try {
      const response = await this.axiosInstance.post(authUrl, credentials)
      this.token = response.data.token
      console.log('Token retrieved:', this.token)
      return this.token
    } catch (error) {
      console.error('Error retrieving token:', error)
      throw error
    }
  }
}

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000'

const client = new ApiClient(BASE_URL)

export default client
