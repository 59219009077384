import { format, parseISO } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../../../services/Redux/slices/EventsSlice'
import Crop from '../Add/Crop'
// import { updateEvent } from '../../../../services/Redux/slices/EventSlice'

const EditEventModal = ({ isOpen, onClose, event, columns }) => {
  const formattedEvent = {
    ...event,
    dateStart: event.dateStart
      ? new Date(event.dateStart).toISOString().slice(0, 16)
      : '',
    dateEnd: event.dateEnd
      ? new Date(event.dateEnd).toISOString().slice(0, 16)
      : ''
  }
  const dispatch = useDispatch()
  useEffect(() => {
    setFormData(formattedEvent)
  }, [event])

  const [formData, setFormData] = useState(formattedEvent)
  const [errors, setErrors] = useState({});
  const [croppedImage, setCroppedImage] = useState(null)
  const [imageFormat, setImageFormat] = useState(null)
  const fileInputRef = useRef(null)

  const handleInputChange = (e, key, nestedKey) => {
    if (e.target.type === 'file') {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        if (nestedKey) {
          setFormData({
            ...formData,
            [key]: { ...formData[key], [nestedKey]: event.target.result }
          })
        } else {
          setFormData({ ...formData, [key]: event.target.result })
          setImageFormat(file.type.split('/')[1])
        }
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    } else {
      let value = e.target.value
      if (nestedKey) {
        setFormData({
          ...formData,
          [key]: { ...formData[key], [nestedKey]: value }
        })
      } else {
        setFormData({ ...formData, [key]: value })
      }
    }
  }

  const validateForm = () => {
    let validationErrors = {}
    let isValid = true
    const addressColumnKeys = ['rue', 'ville', 'region', 'pays', 'codePostale']

    // Check required fields
    columns.forEach((column) => {
      if (
        column.required &&
        !formData[column.key] &&
        !addressColumnKeys.includes(column.key)
      ) {
        validationErrors[column.key] = `${column.label} is required`
        isValid = false
      }
    })

    // Validate address fields separately
    const addressErrors = addressColumnKeys.filter((key) => {
      const column = columns.find((c) => c.key === key)
      return column.required && !formData.address?.[key]
    })
    console.log(formData)
    if (addressErrors.length > 0) {
      addressErrors.forEach((errorKey) => {
        validationErrors[errorKey] = `${
          errorKey.charAt(0).toUpperCase() + errorKey.slice(1)
        } is required`
      })
      isValid = false
    }

    // Check date constraints
    const now = new Date()
    const dateStart = formData.dateStart ? new Date(formData.dateStart) : null
    const dateEnd = formData.dateEnd ? new Date(formData.dateEnd) : null

    if (dateStart && dateStart < now) {
      validationErrors.dateStart = 'Start date must be in the future'
      isValid = false
    }

    if (dateEnd && dateStart && dateEnd < dateStart) {
      validationErrors.dateEnd = 'End date must be after start date'
      isValid = false
    }

    setErrors(validationErrors)
    return isValid
  }

  const handleEditEvent = () => {
    if (!validateForm()) {
      return
    }

    formData.rue = formData.address.rue
    formData.ville = formData.address.ville
    formData.region = formData.address.region
    formData.pays = formData.address.pays
    formData.codePostale = formData.address.codePostale
    formData.address = formData.address._id

    const formattedData = {
      ...formData,
      dateStart: formData.dateStart
        ? format(parseISO(formData.dateStart), 'yyyy-MM-dd HH:mm:ss')
        : null,
      dateEnd: formData.dateEnd
        ? format(parseISO(formData.dateEnd), 'yyyy-MM-dd HH:mm:ss')
        : null,
      image: croppedImage // Include cropped image in formatted data
    }

    dispatch(updateEvent(formattedData))
    onClose()
  }

  const addressFields = ['rue', 'ville', 'region', 'pays', 'codePostale']

  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Edit event</h2>

        {columns
          .filter((column) => !addressFields.includes(column.key))
          .map((column) => (
            <div key={column.key} className='mb-4'>
              <label className='block text-sm font-medium text-gray-700 my-3'>
                {column.label}
                {column.required && (
                  <span className='text-red-500 ml-1'>*</span>
                )}
              </label>
              {column.type === 'select' ? (
                <select
                  value={formData[column.key] || ''}
                  onChange={(e) => handleInputChange(e, column.key)}
                  className='border border-gray-300 rounded-md p-2 w-full'
                >
                  <option value=''>Select {column.label}</option>
                  {column.options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : column.type === 'file' ? (
                <div>
                  <input
                    ref={fileInputRef}
                    type='file'
                    accept='image/*'
                    onChange={(e) => {
                      handleInputChange(e, column.key)
                      const file = e.target.files[0]
                      const reader = new FileReader()
                      reader.onload = (event) => {
                        setFormData({
                          ...formData,
                          [column.key]: event.target.result
                        })
                        setImageFormat(file.type.split('/')[1])
                      }
                      if (file) {
                        reader.readAsDataURL(file)
                      }
                    }}
                    className='border border-gray-300 rounded-md p-2 w-full'
                  />
                  {formData[column.key] && formData[column.key].length > 0 && (
                   <Crop image={formData[column.key]} onCroppedImage={setCroppedImage} format={imageFormat} />
                  )}
                </div>
              ) : (
                <input
                  type={column.type}
                  placeholder={column.label}
                  value={formData[column.key] || ''}
                  onChange={(e) => handleInputChange(e, column.key)}
                  className='border border-gray-300 rounded-md p-2 w-full'
                />
              )}
              {errors[column.key] && (
                <p className='text-red-500 text-sm'>{errors[column.key]}</p>
              )}
            </div>
          ))}

        <div className='mb-4'>
          <h3 className='text-md font-semibold mb-2'>Address</h3>
          {columns
            .filter((column) => addressFields.includes(column.key))
            .map((column) => (
              <div key={column.key} className='mb-2'>
                <label className='block text-sm font-medium text-gray-700'>
                  {column.label}
                  {column.required && (
                    <span className='text-red-500 ml-1'>*</span>
                  )}
                </label>
                <input
                  type={column.type}
                  placeholder={column.label}
                  value={
                    formData.address ? formData.address[column.key] || '' : ''
                  }
                  onChange={(e) => handleInputChange(e, 'address', column.key)}
                  className='border border-gray-300 rounded-md p-2 w-full'
                />
                {errors[column.key] && (
                  <p className='text-red-500 text-sm'>{errors[column.key]}</p>
                )}
              </div>
            ))}
        </div>
        {Object.keys(errors).length > 0 && (
          <p className='text-red-500 text-sm mb-4'>
            Please check the highlighted fields for errors.
          </p>
        )}
        <div className='flex justify-end'>
          <button
            onClick={onClose}
            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Cancel
          </button>
          <button
            onClick={handleEditEvent}
            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditEventModal
