import React from 'react'

const NavMenuLinksWrapper = ({ children, hideTooltip, tooltipLabel }) => {
  if (hideTooltip) {
    return <>{children}</>
  }

  return <div>{children}</div>
}

export default NavMenuLinksWrapper
