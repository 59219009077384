import { useState, useEffect } from 'react'

const useToggle = (
  initialValue,
  saveToLocalStorage = false,
  localStorageKey = ''
) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (saveToLocalStorage) {
      const storedValue = localStorage.getItem(localStorageKey)
      if (storedValue) {
        setValue(JSON.parse(storedValue))
      }
    }
  }, [saveToLocalStorage, localStorageKey])

  const toggleValue = () => {
    setValue((prevValue) => !prevValue)
  }

  useEffect(() => {
    if (saveToLocalStorage) {
      localStorage.setItem(localStorageKey, JSON.stringify(value))
    }
  }, [value, saveToLocalStorage, localStorageKey])

  return [value, toggleValue]
}

export default useToggle
