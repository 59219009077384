import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login, reset } from '../services/Redux/slices/authSlice'
import leftImage from '../assets/images/MCT hq.png'
import rightImage from '../assets/images/MCT hq.svg'

const Login = () => {
  const { user, error, loading } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({ email: '', password: '' })
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
    return () => {
      dispatch(reset())
    }
  }, [user, navigate, dispatch])

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(login(userData))
  }

  return (
    <div className='flex h-screen bg-gray-100'>
      {/* Left side - Image with overlay */}
      <div className='flex-1 relative hidden lg:flex items-center justify-center'>
        <div className='relative w-full h-full'>
          <div className='absolute inset-0 bg-primaryColor opacity-90'></div>
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <img src={leftImage} alt='' width={'100%'} />
          </div>
        </div>
      </div>

      {/* Right side - Login Form */}
      <div className='flex-1 bg-white p-8 rounded shadow-md flex items-center justify-center'>
        <div className='w-full max-w-md'>
          <div className='flex justify-center lg:hidden object-cover mb-16 bg-white'>
            <img src={rightImage} alt='mine-clap tickets ' width={'70%'} />
          </div>
          <div className="hidden lg:block">
            <h2 className='text-gray-800 text-3xl font-bold mb-6'>Welcome to MineClap Tickets !</h2>
            <h3 className=' text-xl font-semibold mb-6 text-gray-500'>Login to your Events' Dashboard</h3>
          </div>

          <form className='space-y-6' onSubmit={handleLogin}>
            <div className='flex flex-col'>
              <label htmlFor='email' className='text-gray-700 text-lg font-medium mb-2'>Email</label>
              <input
                type='text'
                id='email'
                name='email'
                className='border border-gray-300 rounded-sm py-3 px-4 focus:outline-none focus:border-pink-700 text-lg w-full'
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                placeholder='Email Address'
                value={userData.email}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor='password' className='text-gray-700 text-lg font-medium mb-2'>Password</label>
              <input
                type='password'
                id='password'
                name='password'
                className='border border-gray-300 rounded-sm py-3 px-4 focus:outline-none focus:border-pink-700 text-lg w-full'
                onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                placeholder='Enter password'
                value={userData.password}
              />
            </div>
            <div className='flex justify-between items-center'>
              <button
                type='submit'
                className='bg-primaryColor opacity-90  text-white font-semibold w-full py-3 rounded-sm focus:outline-none focus:bg-primaryColorLight hover:bg-primaryColorLight'
                disabled={loading}
              >
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </div>
            {error && (
              <div className='mt-4 font-medium text-red-500'>
                {typeof error === 'string' ? error : (
                  <>
                    {error && <p>{error.msg}</p>}
                  </>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
