import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../../services/Redux/slices/authSlice';

const EditPassword = ({ isOpen, onClose }) => {
    const dispatch = useDispatch()
    const { loading, error, success } = useSelector((state) => state.auth)

    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();
    
    const [errors, setErrors] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if (!isOpen) {
            oldPasswordRef.current.value = '';
            newPasswordRef.current.value = '';
            confirmNewPasswordRef.current.value = '';
            setErrors({
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
        }
    }, [isOpen]);

    useEffect(() => {
        if (success && !loading) {
            onClose();
        }
    }, [success, loading, onClose]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) return;

        const oldPassword = oldPasswordRef.current.value;
        const newPassword = newPasswordRef.current.value;
        const confirmNewPassword = confirmNewPasswordRef.current.value;
        
        let newErrors = {};

        if (!oldPassword.trim()) {
            newErrors.oldPassword = 'Current password is required';
        }

        if (!newPassword.trim()) {
            newErrors.newPassword = 'New password is required';
        } else if (newPassword.length < 8) {
            newErrors.newPassword = 'Password must be at least 8 characters long';
        }

        if (!confirmNewPassword.trim()) {
            newErrors.confirmPassword = 'Confirm password is required';
        } else if (newPassword !== confirmNewPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            dispatch(changePassword({
                oldPassword: oldPasswordRef.current.value,
                newPassword: newPasswordRef.current.value
            }))
        }
    };

    const handleClose = () => {
        if (!loading) {
            onClose();
        }
    };

    return (
        <div
            className={`fixed inset-0 z-50 overflow-auto ${isOpen ? 'flex' : 'hidden'}`}
        >
            <div className='fixed inset-0 bg-black bg-opacity-50'></div>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
                <h2 className='text-lg font-semibold mb-4'>Change Password</h2>

                <form onSubmit={handleSubmit}>
                    <label className='block text-sm font-medium text-gray-700 my-3'>
                        Current Password
                        <span className='text-red-500 ml-1'>*</span>
                    </label>
                    <input
                        type="password"
                        placeholder="Current password"
                        ref={oldPasswordRef}
                        className='border border-gray-300 rounded-md p-2 w-full'
                        disabled={loading}
                    />
                    {errors.oldPassword && <p className='text-red-500 text-sm mt-1'>{errors.oldPassword}</p>}

                    <label className='block text-sm font-medium text-gray-700 my-3'>
                        New Password
                        <span className='text-red-500 ml-1'>*</span>
                    </label>
                    <input
                        type="password"
                        placeholder="New password"
                        ref={newPasswordRef}
                        className='border border-gray-300 rounded-md p-2 w-full'
                        disabled={loading}
                    />
                    {errors.newPassword && <p className='text-red-500 text-sm mt-1'>{errors.newPassword}</p>}

                    <label className='block text-sm font-medium text-gray-700 my-3'>
                        Confirm New Password
                        <span className='text-red-500 ml-1'>*</span>
                    </label>
                    <input
                        type="password"
                        placeholder="Confirm new password"
                        ref={confirmNewPasswordRef}
                        className='border border-gray-300 rounded-md p-2 w-full'
                        disabled={loading}
                    />
                    {errors.confirmPassword && <p className='text-red-500 text-sm mt-1'>{errors.confirmPassword}</p>}

                    {error && <p className='text-red-500 text-sm mt-2'>{error}</p>}

                    <div className='flex justify-end mt-4'>
                        <button
                            type="button"
                            onClick={handleClose}
                            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50'
                            disabled={loading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight disabled:opacity-50'
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPassword;