import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateTicket } from '../../../../services/Redux/slices/tickets'

const EditTicketTypeModal = ({ isOpen, onClose, ticket, columns }) => {
  const dispatch = useDispatch()
  const statusOptions = ["valid", "expired", "entered", "invalid"];
  const [formData, setFormData] = useState(ticket.status)
  const [isConfirming, setIsConfirming] = useState(false)

  const handleInputChange = (e) => {
    setFormData(e.target.value)
  }

  const handleEditEvent = () => {
    setIsConfirming(true)
  }

  const handleConfirmEdit = () => {
    dispatch(updateTicket({ id: ticket._id, status: formData }))
    onClose()
  }

  return (
    <div className={`fixed inset-0 z-50 overflow-auto ${isOpen ? 'flex' : 'hidden'}`}>
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Edit Ticket Type</h2>
        
        {!isConfirming ? (
          <>
            {columns.map((column) => (
              <div key={column.key} className='mb-4'>
                <label className='block text-sm font-medium text-gray-700 my-3'>
                  {column.label}
                  {column.required && (
                    <span className='text-red-500 ml-1'>*</span>
                  )}
                </label>
                <select
                  className='border border-gray-300 rounded-md p-2 w-full'
                  value={formData || ''}
                  onChange={handleInputChange}
                >
                  {statusOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            ))}
            
            <div className='flex justify-end'>
              <button
                onClick={onClose}
                className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
              >
                Cancel
              </button>
              <button
                onClick={handleEditEvent}
                className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
              >
                Edit
              </button>
            </div>
          </>
        ) : (
          <>
            <p className='mb-4'>Are you sure you want to change the status to <span className="font-bold">{formData}</span>?</p>
            <div className='flex justify-end'>
              <button
                onClick={() => setIsConfirming(false)}
                className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmEdit}
                className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
              >
                Confirm
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EditTicketTypeModal
