import { useState } from 'react'
import {
  HiOutlineMenu,
  HiOutlineHome,
  HiOutlineDocumentText,
  HiOutlineUsers
} from 'react-icons/hi'
import { GiHamburgerMenu } from 'react-icons/gi'
const NavBurger = (props) => {
  const [changeStroke, setChangeStroke] = useState(false)

  return (
    <button
      {...props}
      className='h-[30px]'
      onMouseEnter={() => setChangeStroke(true)}
      onMouseLeave={() => setChangeStroke(false)}
    >
      <GiHamburgerMenu fill='white' />
    </button>
  )
}

export default NavBurger
