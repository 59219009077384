import React, { useEffect, useState } from 'react'
import { CapacitorBarcodeScanner } from '@capacitor/barcode-scanner'
import { FaQrcode, FaRegCheckCircle } from 'react-icons/fa'
import { CgSearchLoading } from "react-icons/cg";
import { BiError } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTicketInfo,
  resetScannedTicket,
  updateTicket
} from '../../../services/Redux/slices/tickets'

const QRCodeScanner = () => {
  const [scannedData, setScannedData] = useState('')

  const { scannedTicket,loading } = useSelector((state) => state.tickets)
  const dispatch = useDispatch()

  const startScan = async () => {
    try {
      setScannedData('')
      dispatch(resetScannedTicket())
      const result = await CapacitorBarcodeScanner.scanBarcode({
        hint: 'ALL',
        scanInstructions: 'Scan a QR code',
        scanButton: true,
        cameraDirection: 1,
        scanOrientation: 1,
        android: {
          scanningLibrary: 'mlkit'
        },
        web: {
          showCameraSelection: true,
          scannerFPS: 30
        }
      })
      
      if (result.ScanResult) {
        const parsedResult = JSON.parse(result.ScanResult)
        setScannedData(parsedResult)
      }
    } catch (error) {
      console.error('Error starting scan:', error)
    }
  }

  useEffect(() => {
    if (scannedData.ticketId) {
      dispatch(updateTicket({ id: scannedData.ticketId, status: 'entered' }))
      dispatch(getTicketInfo(scannedData.ticketId))
    }
  }, [scannedData, dispatch])

  return (
    <div className='flex justify-center items-center min-h-[80vh] '>
      <div className='bg-white p-6 rounded-lg shadow-lg w-full max-w-md'>
        <h1 className='text-3xl font-bold mb-4 text-center flex items-center justify-center'>
          <FaQrcode className='mr-2' />
          QR Code Scanner
        </h1>
        <button
          onClick={startScan}
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full flex items-center justify-center'
        >
          <FaQrcode className='mr-2' />
          Start Scan
        </button>
      
        {scannedData && (
          <div className='mt-4 p-4 bg-gray-50 rounded shadow-inner'>
            <div className='flex items-center justify-center mb-2'>
              {scannedTicket && scannedTicket.status === 'valid' && (
                <>
                  <FaRegCheckCircle className='text-green-500 mr-2' size={30} />
                  <h2 className='text-lg font-semibold text-gray-700'>Valid</h2>
                </>
              )}
              {scannedTicket && scannedTicket.status === 'entered' && (
                <>
                  <BiError className='text-red-600 mr-2' size={30}></BiError>{' '}
                  <h2 className='text-lg font-semibold text-gray-700'>
                    Entered
                  </h2>
                </>
              )}
              {scannedTicket &&
                scannedTicket.status !== 'valid' &&
                scannedTicket.status !== 'entered' &&   (
                  <>
                    <BiError className='text-red-600 mr-2' size={30}></BiError>{' '}
                    <h2 className='text-lg font-semibold text-gray-700'>
                      Invalid
                    </h2>
                  </>
                )}
              {loading && !scannedTicket && (
                <>
                  <CgSearchLoading className='text-yellow-600 mr-2' size={30}></CgSearchLoading>
                  <h2 className='text-lg font-semibold text-gray-700'>
                    Loading
                  </h2>
                </>
              )}
              {!scannedTicket  && !loading && (
                <>
                  <BiError className='text-red-600 mr-2' size={30}></BiError>{' '}
                  <h2 className='text-lg font-semibold text-gray-700'>
                    Invalid
                  </h2>
                </>
              )}
            </div>
            <div className='text-gray-700'>
              {scannedTicket &&
                Object.entries(scannedData).map(([key, value]) => (
                  <div key={key} className='flex  items-center gap-2'>
                    <p className='font-semibold'>{key} :</p>

                    <p>{value}</p>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default QRCodeScanner
