import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const prefix = 'events'

export const getEvents = createAsyncThunk('events', async (_, thunkApi) => {
  try {
    const response = await client.get(`${prefix}/get`, {
      headers: { Authorization: `Bearer ${getToken()?.user}` }
    })

    if (response.data) return response.data
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addEvent = createAsyncThunk(
  'events/addEvent',
  async (eventData, thunkApi) => {
    try {
      const { image, ...rest } = eventData
      const formData = new FormData()
      formData.append('image', image)
      Object.keys(rest).forEach((key) => {
        formData.append(key, rest[key])
      })

      const response = await client.post(`${prefix}/add`, formData, {
        headers: {
          Authorization: `Bearer ${getToken()?.user}`,
          'Content-Type': 'multipart/form-data'
        }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (eventData, thunkApi) => {
    try {
      const { image, ...rest } = eventData
      console.log(eventData)
      const formData = new FormData()
      formData.append('image', image)
      Object.keys(rest).forEach((key) => {
        formData.append(key, rest[key])
      })

      const response = await client.put(
        `${prefix}/update/${eventData._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()?.user}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (eventId, thunkApi) => {
    try {
      const response = await client.delete(`${prefix}/delete/${eventId}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return eventId
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const EventsSlice = createSlice({
  name: 'events',
  initialState: {
    events: [],
    error: '',
    success: false,
    loading: false
  },
  reducers: {
    resetEvent: (state) => {
      state.error = ''
      state.success = false
      state.events = []
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.events = action.payload
      state.success = true
      state.loading = false
    })
    builder.addCase(getEvents.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
    builder.addCase(getEvents.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(addEvent.fulfilled, (state, action) => {
      state.success = true
      state.events.push(action.payload)
      state.events.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      state.loading = false
    })

    builder.addCase(addEvent.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
    builder.addCase(addEvent.pending, (state, action) => {
      state.loading = true
    })

    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.success = true
      state.events = state.events.map((event) =>
        event._id === action.payload._id ? action.payload : event
      )
    })
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.success = true
      console.log(action.payload)
      console.log(state.events)
      state.events = state.events.filter(
        (event) => event._id !== action.payload
      )
    })
  }
})

export const { resetEvent } = EventsSlice.actions
export default EventsSlice.reducer
