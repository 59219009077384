import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'


import { format, parseISO } from 'date-fns'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import { deleteTeamMember, getMyTeamMembers } from '../services/Redux/slices/TeamSlice'
import AddTicketType from '../components/Core/CRUD/Add/AddTicketType'
import EditTypeModal from '../components/Core/CRUD/Edit/EditTicketTypeModal'
import AddMember from '../components/Core/CRUD/Add/AddMember'
import EditMember from '../components/Core/CRUD/Edit/EditMember'

const Team = () => {
  const { team, loading } = useSelector((state) => state.teams)
  const dispatch = useDispatch()



  useEffect(() => {
    dispatch(getMyTeamMembers())
  }, [dispatch])

  const columns = [
    { label: 'First Name', key: 'profile.firstName' },
    { label: 'Last Name', key: 'profile.lastName' },
    { label: 'Email', key: 'email.address' },
    { label: 'Phone Number', key: 'profile.phone' },
    { label: 'Role', key: 'role' },
    { label: 'Password', key: 'password.password' },
  ]

  const toAddColumns = [
    { label: 'First Name', key: 'profile.firstName', required: true },
    { label: 'Last Name', key: 'profile.lastName', required: true },
    { label: 'Email', key: 'email.address', required: true },
    { label: 'Phone Number', key: 'profile.phone', required: true },
    {
      label: 'Role',
      key: 'role',
      type: 'select', // Set this as a select field
      options: [
        { id: 'CONTROLLER', name: 'CONTROLLER' },
        { id: 'SELLER', name: 'SELLER' }
      ],
      required: true
    },
    { label: 'Password', key: 'password.password', required: true },
  ]


  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  useEffect(() => {
    if (selectedMember) {
      setIsEditModalOpen(true)
    }
  }, [selectedMember])


  const handleEdit = async (updatedEvent) => {
    setIsEditModalOpen(false)
  }

  const handleEditClick = (member) => {
    setSelectedMember(member)
    setIsEditModalOpen(true)
  }

  // if (loading) {
  //   return <LoadingSpinner />
  // }

  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium'>Team Members</p>
        <button
          onClick={openModal}
          className='bg-[#D73556] p-2 rounded-sm text-white px-3 font-medium'
        >
          Add New Member
        </button>
        <AddMember
          columns={toAddColumns}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </div>

      {
        loading && !isModalOpen ?
          <LoadingSpinner /> :

          <DataTable columns={columns} data={team}
            onEditClick={handleEditClick}
            onDeleteClick={(id) => dispatch(deleteTeamMember(id))} ></DataTable>
      }
      {isEditModalOpen && (
        <EditMember
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEdit}
          memberSelected={selectedMember}
          columns={toAddColumns}
        />
      )}
    </div>
  )
}

export default Team