import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, roles }) => {
  const { user } = useSelector((state) => state.auth);
  let location = useLocation();

  if (!user) {
    // Redirect to login if user is not logged in
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (roles && !roles.includes(user.role)) {
    // Redirect to unauthorized if user's role is not allowed
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
