import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Dashboard from './pages/Dashboard'

import Login from './pages/login'

import { useSelector } from 'react-redux'
import ProtectedRoute from './utils/ProtectedRoute'
import Events from './pages/Events'
import TicketTypes from './pages/TicketTypes'
import Links from './pages/Links'
import Tickets from './pages/tickets'
import GeneratedLink from './pages/GeneratedLink'
import Logout from './pages/logout'
import QRCodeScanner from './components/Core/Barcode/Scanner'
import Team from './pages/Team'
import { Roles } from './enum'
import Home from './components/Core/Dashboard/Home'

const App = () => {
  return (
    <Routes>
      <Route path='/ticket/:id' element={<GeneratedLink />} />
      <Route
        path='/dashboard'
        element={
          <ProtectedRoute roles={[Roles.USER, Roles.SELLER]}>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        {/* Routes accessible by user */}
        {/* <Route path='' element={<QRCodeScanner />} /> */}
        <Route path='' element={<Home/>} />
        <Route path='events' element={<ProtectedRoute roles={[Roles.USER]}><Events /></ProtectedRoute>} />
        <Route path='ticketTypes' element={<ProtectedRoute roles={[Roles.USER]}><TicketTypes /></ProtectedRoute>} />
        <Route path='tickets' element={<ProtectedRoute roles={[Roles.USER]}><Tickets /></ProtectedRoute>} />
        <Route path='team' element={<ProtectedRoute roles={[Roles.USER]}><Team /></ProtectedRoute>} />

        {/* Route accessible by both user and seller */}
        <Route path='links' element={<ProtectedRoute roles={[Roles.USER, Roles.SELLER]}><Links /></ProtectedRoute>} />

        {/* Common route */}
        <Route path='logout' element={<Logout />} />
      </Route>
      <Route path='' element={<Login />} />
      <Route path='login' element={<Login />} />
    </Routes>
  )
}

export default App
