import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addEvent } from '../../../../services/Redux/slices/EventsSlice'
import { format, parseISO, isAfter, isBefore } from 'date-fns'
import Crop from './Crop'
import LoadingSpinner from '../../../Layout/LoadingSpinner'

const AddEvent = ({ isOpen, onClose, onAdd, columns }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({})
  const [croppedImage, setCroppedImage] = useState(null)
  const [imageFormat, setImageFormat] = useState(null)
  const fileInputRef = useRef(null)
  const [errors, setErrors] = useState({})

  const { loading } = useSelector((state) => state.events)
  useEffect(() => {
    if (isOpen) {
      setFormData({})
      setCroppedImage(null)
      setImageFormat(null)
      setErrors({})
      if (fileInputRef.current) {
        fileInputRef.current.value = null
      }
    }
  }, [isOpen])

  const handleInputChange = (e, key) => {
    if (e.target.type === 'file') {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        setFormData({ ...formData, [key]: event.target.result })
        setImageFormat(file.type.split('/')[1])
      }
      if (file) {
        reader.readAsDataURL(file)
        console.log(imageFormat)
      }
    } else {
      let value = e.target.value
      setFormData({ ...formData, [key]: value })
    }
  }

  const validateForm = () => {
    const newErrors = {}
    const requiredFields = columns.filter((column) => column.required)

    requiredFields.forEach((field) => {
      if (!formData[field.key]) {
        newErrors[field.key] = `${field.label} is required`
      }
    })

    if (formData.dateStart && formData.dateEnd) {
      const dateStart = parseISO(formData.dateStart)
      const dateEnd = parseISO(formData.dateEnd)
      const now = new Date()

      if (isBefore(dateEnd, dateStart)) {
        newErrors.dateEnd = 'End date must be after start date'
      }

      if (isBefore(dateStart, now)) {
        newErrors.dateStart = 'Start date must be in the future'
      }
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleAddEvent = () => {
    if (validateForm()) {
      const formattedData = {
        ...formData,
        dateStart: formData.dateStart
          ? format(parseISO(formData.dateStart), 'yyyy-MM-dd HH:mm:ss')
          : null,
        dateEnd: formData.dateEnd
          ? format(parseISO(formData.dateEnd), 'yyyy-MM-dd HH:mm:ss')
          : null,
        image: croppedImage
      }

      console.log('Formatted data:', formattedData)
      dispatch(addEvent(formattedData))
      onClose()
    }
  }

  if (loading) return <LoadingSpinner></LoadingSpinner>
  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Add Event</h2>

        {columns.map((column) => (
          <div key={column.key} className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              {column.label}
              {column.required && <span className='text-red-500 ml-1'>*</span>}
            </label>
            {column.type === 'select' ? (
              <select
                value={formData[column.key] || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
              >
                <option value=''>Select {column.label}</option>
                {column.options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : column.type === 'file' ? (
              <div>
                <input
                  ref={fileInputRef}
                  type='file'
                  accept='.jpg,.jpeg,.png'
                  onChange={(e) => {
                    handleInputChange(e, column.key)
                    const file = e.target.files[0]
                    const reader = new FileReader()
                    reader.onload = (event) => {
                      setFormData({
                        ...formData,
                        [column.key]: event.target.result
                      })
                    }
                    if (file) {
                      reader.readAsDataURL(file)
                    }
                  }}
                  className='border border-gray-300 rounded-md p-2 w-full'
                />

                {formData[column.key] && (
                  <Crop
                    image={formData[column.key]}
                    onCroppedImage={setCroppedImage}
                    format={imageFormat}
                  />
                )}
              </div>
            ) : (
              <input
                type={column.type}
                placeholder={column.label}
                value={formData[column.key] || ''}
                onChange={(e) => handleInputChange(e, column.key)}
                className='border border-gray-300 rounded-md p-2 w-full'
                min={column.type === 'number' ? 0 : undefined} // Limit the number input to start at 0
              />
            )}
            {errors[column.key] && (
              <p className='text-red-500 text-sm'>{errors[column.key]}</p>
            )}
          </div>
        ))}
        {Object.keys(errors).length > 0 && (
          <p className='text-red-500 text-sm mb-4'>
            Please check the highlighted fields for errors.
          </p>
        )}
        <div className='flex justify-end'>
          <button
            onClick={onClose}
            className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
          >
            Cancel
          </button>
          <button
            onClick={handleAddEvent}
            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
          >
            Add
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddEvent
